import { OutreachAttemptResult } from "@oben-core-web/models/outreach-attempt";
import { object, string, date } from "yup";

const outreachAttemptResultSchema = object({
  date: date(),
  staffId: string().required(),
  staffName: object()
    .shape({
      first: string(),
      last: string(),
      display: string()
    })
    .required(),
  note: string().nullable(),
  result: string()
    .oneOf(
      [
        OutreachAttemptResult.LeftMessage,
        OutreachAttemptResult.NoAnswer,
        OutreachAttemptResult.NotAvailable,
        OutreachAttemptResult.SpokeToClient,
        OutreachAttemptResult.WrongContactInfo
        // OutreachAttemptResult.Unknown
      ],
      "Invalid Outreach Attempt Result type"
    )
    .required("Outreach attempt result is required")
});

export default outreachAttemptResultSchema;
