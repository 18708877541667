import { useEffect, useState } from "react";
import { Barbershop } from "@oben-core-web/models/barbershop";
import { BarbershopService } from "@oben-core-web/services/barbershop-service";

const useBarbershops = () => {
  const [barbershops, setBarbershops] = useState<Barbershop[]>([]);
  useEffect(() => {
    let loading = false;
    const fetchBarbershops = async () => {
      if (!loading) {
        const barbershopService = new BarbershopService();
        const shops = await barbershopService.getAllBarbershops();
        setBarbershops(shops);
      }
    };
    fetchBarbershops();
    return () => {
      loading = true;
    };
  }, []);
  return { barbershops };
};

export default useBarbershops;
