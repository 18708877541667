import useCurrentUser from "@/hooks/useCurrentUser";
import useOutreach from "@/hooks/useOutreach";
import React, { useMemo, useState, useRef } from "react";
import { AgGridReact, CustomCellEditorProps } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import {
  Button,
  HStack,
  IconButton,
  useDimensions,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { OutreachService } from "@oben-core-web/services/outreach-service";
import {
  Outreach,
  OutreachPurpose,
  OutreachStatus
} from "@oben-core-web/models/outreach";
import { UserName } from "@oben-core-web/models/user-name";
import OutreachAttemptForm from "@components/molecules/OutreachAttemptForm";
import { ClientType, ContactMethod } from "@oben-core-web/constants/core-enums";
import { MdListAlt, MdPhone } from "react-icons/md";

interface IPatientOutreachTable {
  outreachPurposes?: OutreachPurpose[];
}

const PatientOutreachTable = ({
  outreachPurposes = []
}: IPatientOutreachTable) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(wrapperRef, true);
  const { isOpen: attemptFormOpen, onToggle: toggleAttemptForm } =
    useDisclosure();
  const { isOpen: outreachInfoModalOpen, onToggle: toggleOutreachInfoModal } =
    useDisclosure();
  const { currentUser } = useCurrentUser();
  const { outreaches } = useOutreach(
    currentUser?.placeBasedCareProvId ?? "",
    outreachPurposes
  );
  const [selectedOutreachItem, setSelectedOutreachItem] = useState<Outreach>();
  const outreachRows = outreaches.map((or) => {
    return {
      ...or,
      clientDisplayName: or.clientName.display ?? "",
      staffDisplayName: or.staffName.display ?? "",
      lastAttemptResult: or.attempts[or.attempts.length - 1]?.result
    };
  });
  const columnHeaders: ColDef<
    Outreach & {
      clientDisplayName: string;
      staffDisplayName: string;
      lastAttemptResult: string;
      actions: any;
      width?: number;
    }
  >[] = useMemo(() => {
    let width: number = 0;
    if (dimensions) {
      width = dimensions.contentBox.width;
    }
    const totalWidth = 200 + 215 + 135 + 165 + 110 + 125 + 150 + 85;
    const subDivWidth = (width - totalWidth) / 8.25; // divide by more 1.25 extra columns to avoid horizontal scroll due to extra table padding
    return [
      {
        field: "clientDisplayName",
        headerName: "Patient",
        width: 200 + (totalWidth < width ? subDivWidth : 0)
      },
      {
        field: "clientEmail",
        headerName: "Email",
        width: 215 + (totalWidth < width ? subDivWidth : 0)
      },
      {
        field: "clientPhone",
        headerName: "Phone",
        width: 135 + (totalWidth < width ? subDivWidth : 0)
      },
      {
        field: "purpose",
        headerName: "Purpose",
        filter: true,
        filterParams: {
          values: [
            OutreachPurpose.ConfirmAppointment,
            OutreachPurpose.NewEnrollment,
            OutreachPurpose.SchedFollowUp
          ]
        },
        width: 165 + (totalWidth < width ? subDivWidth : 0),
        valueFormatter: (v) => startCase(v.data?.purpose)
      },
      {
        field: "status",
        headerName: "Status",
        width: 110 + (totalWidth < width ? subDivWidth : 0),
        filter: true,
        editable: true,
        cellEditor: OutreachStatusSelector,
        valueFormatter: (v) => startCase(v.data?.status)
      },
      {
        field: "staffDisplayName",
        headerName: "Assigned To",
        width: 125 + (totalWidth < width ? subDivWidth : 0),
        filter: true,
        editable: true,
        cellEditor: OutreachAsigneeSelector
        // cellEditorPopupPosition: "under"
      },
      {
        field: "lastAttemptResult",
        headerName: "Last Attempt",
        filter: true,
        width: 150 + (totalWidth < width ? subDivWidth : 0),
        valueFormatter: (v) => startCase(v.data?.lastAttemptResult)
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 80 + (totalWidth < width ? subDivWidth : 0),
        cellRenderer: (cellClickEvent: any) => {
          return (
            <HStack h={"full"} spacing={1}>
              <IconButton
                aria-label='outerach-icon-btn'
                onClick={() => {
                  const outreachItem = outreaches.find(
                    (or) => or.id === cellClickEvent.data.id
                  );
                  outreachItem && setSelectedOutreachItem(outreachItem);
                  toggleAttemptForm();
                }}
                rounded={"xl"}
                variant={"ghost"}
                size={"xs"}
                // colorScheme='teal'
                icon={<MdPhone size={20} />}
              />
              <IconButton
                aria-label='outerach-icon-btn'
                onClick={() => {
                  const outreachItem = outreaches.find(
                    (or) => or.id === cellClickEvent.data.id
                  );
                  outreachItem && setSelectedOutreachItem(outreachItem);
                  toggleOutreachInfoModal();
                }}
                rounded={"xl"}
                variant={"ghost"}
                size={"xs"}
                // colorScheme='teal'
                icon={<MdListAlt size={20} />}
              />
            </HStack>
          );
        },
        sortable: false,
        filter: false
      }
    ];
  }, [toggleAttemptForm, toggleOutreachInfoModal, outreaches, dimensions]);

  const totalColumnWidth = columnHeaders.reduce((a, c) => {
    return a + (c.width ?? 0);
  }, 0);

  return (
    <>
      <div
        className='ag-theme-quartz'
        style={{ height: "89%", width: "100%" }}
        ref={wrapperRef}
      >
        <Button
          size='xs'
          onClick={async () => {
            if (!currentUser) return;
            const outreachService = new OutreachService();
            const outreach = new Outreach({
              id: "",
              placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? "",
              clientId: "7TsZjLoBNkBc3PHYGHyX",
              clientType: ClientType.ClientUser,
              clientName: new UserName({
                first: "H",
                last: "L",
                display: "HL"
              }),
              clientPhone: "4152692308",
              clientEmail: "harrison@joinoben.com",
              prefContactMethod: ContactMethod.Phone,
              staffId: currentUser?.uid,
              staffName: currentUser?.name,
              createdDate: new Date(),
              purpose: OutreachPurpose.NewEnrollment,
              status: OutreachStatus.New,
              attempts: []
            });

            await outreachService.addOutreach(outreach);
          }}
        >
          Add Outreach
        </Button>
        Outreach Table
        <AgGridReact
          rowData={outreachRows}
          columnDefs={columnHeaders as any}
          rowSelection='single'
          // onRowSelected={handleRowSelected}
          // onRowDoubleClicked={(row) => row.data && navToPatient(row.data.id)}
          autoSizeStrategy={{
            type: "fitProvidedWidth",
            width: totalColumnWidth
          }}
        />
      </div>
      <OutreachAttemptForm
        outreachItem={selectedOutreachItem}
        isOpen={attemptFormOpen}
        onToggle={() => {
          setSelectedOutreachItem(undefined);
          toggleAttemptForm();
        }}
      />
      {selectedOutreachItem && (
        <PatientOutreachModal
          outreach={selectedOutreachItem}
          isOpen={outreachInfoModalOpen}
          onToggle={() => {
            setSelectedOutreachItem(undefined);
            toggleOutreachInfoModal();
          }}
        />
      )}
    </>
  );
};

export default PatientOutreachTable;

import { Select } from "@chakra-ui/react";
import useWebUsers from "@/hooks/useWebUsers";
import { startCase } from "lodash";
import PatientOutreachModal from "./PatientOutreachModal";

const OutreachStatusSelector = ({
  value,
  onValueChange,
  data,
  api
}: CustomCellEditorProps) => {
  const toast = useToast();
  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    onValueChange(event.target.value);
    const outreachService = new OutreachService();
    const outreach = Outreach.fromMap(data.modelId, data);
    outreach.status = event.target.value as OutreachStatus;
    await outreachService
      .updateOutreach(outreach)
      .then(() => {
        toast({ status: "success", description: "Outreach status updated" });
        api.stopEditing();
      })
      .catch(() => {
        toast({
          status: "error",
          description: "Failed to update outreach status"
        });
      });
    // Tell AG Grid to stop editing, which will trigger onCellValueChanged
  };

  return (
    <Select value={value} onChange={handleChange} placeholder='Select option'>
      <option key={OutreachStatus.New} value={OutreachStatus.New}>
        Not Started
      </option>
      <option key={OutreachStatus.InProgress} value={OutreachStatus.InProgress}>
        In Progress
      </option>
      <option key={OutreachStatus.Completed} value={OutreachStatus.Completed}>
        Completed
      </option>
      <option key={OutreachStatus.Failed} value={OutreachStatus.Failed}>
        Failed
      </option>
    </Select>
  );
};

const OutreachAsigneeSelector = ({
  value,
  onValueChange,
  data,
  api
}: CustomCellEditorProps) => {
  const toast = useToast();
  const { webUsers } = useWebUsers();
  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedWebUser = webUsers.find(
      (wu) => wu.uid === event.target.value
    );
    if (!selectedWebUser) return;
    const outreachService = new OutreachService();
    const outreach = Outreach.fromMap(data.modelId, data);
    outreach.staffId = event.target.value;
    outreach.staffName = selectedWebUser.name;
    await outreachService
      .updateOutreach(outreach)
      .then(() => {
        toast({ status: "success", description: "Outreach asignee updated" });
        // Tell AG Grid to stop editing, which will trigger onCellValueChanged
        api.stopEditing();
        // AG Grid will display the value passed to onValueChange -- use name instead of id
        onValueChange(selectedWebUser.name.display);
      })
      .catch(() => {
        toast({
          status: "error",
          description: "Failed to update outreach asignee"
        });
      });
  };

  return (
    <Select value={value} onChange={handleChange} placeholder='Select option'>
      {webUsers.map((wu) => (
        <option
          key={`outreach-${data.modelId}-webuser-${wu.uid}`}
          value={wu.uid}
        >
          {wu.name.display}
        </option>
      ))}
    </Select>
  );
};
