// TourContext.js
import { initialState, ITourState, tourReducer } from "@/reducers/tourReducer";
import { createContext, ReactNode, useCallback, useReducer } from "react";

interface ITourContext {
  state: ITourState;
  startTour: (steps: any[]) => void;
  setCurrentStep: (currentStep: number) => void;
  next: () => void;
  prev: () => void;
  endTour: () => void;
}

export const TourContext = createContext<ITourContext | undefined>(undefined);

export const TourProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(tourReducer, initialState);

  const startTour = (steps: any[]) => {
    dispatch({ type: "SET_STEPS", payload: { steps } });
    dispatch({ type: "SET_CURRENT_STEP", payload: { currentStep: 0 } });
    dispatch({ type: "SET_TOUR_OPEN", payload: { isTourOpen: true } });
  };

  const endTour = useCallback(() => {
    dispatch({ type: "SET_TOUR_OPEN", payload: { isTourOpen: false } });
    // dispatch({ type: "RESET" });
  }, []);

  const setCurrentStep = useCallback((currentStep: number) => {
    dispatch({ type: "SET_CURRENT_STEP", payload: { currentStep } });
  }, []);

  const next = useCallback(() => {
    dispatch({ type: "NEXT_STEP" });
  }, []);

  const prev = useCallback(() => {
    dispatch({ type: "PREV_STEP" });
  }, []);

  return (
    <TourContext.Provider
      value={{ state, startTour, setCurrentStep, endTour, next, prev }}
    >
      {children}
    </TourContext.Provider>
  );
};
