import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { DateTime } from "luxon";
import {
  OutreachAttempt,
  OutreachAttemptResult
} from "@oben-core-web/models/outreach-attempt";
import { Controller, useForm } from "react-hook-form";
import { Outreach, OutreachPurpose } from "@oben-core-web/models/outreach";
import useCurrentUser from "@/hooks/useCurrentUser";
import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  ModalFooter,
  Button,
  Text,
  Select
} from "@chakra-ui/react";
import { UserName } from "@oben-core-web/models/user-name";
import { OutreachService } from "@oben-core-web/services/outreach-service";
import { useState, useEffect } from "react";
import { startCase } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import outreachAttemptResultSchema from "@/resolvers/outreachAttempt";

interface ILogOutreachModal {
  outreachItem?: Outreach;
  isOpen: boolean;
  onToggle: () => void;
}

interface IOutreachAttemptForm {
  staffId: string;
  staffName: UserName;
  date: Date;
  result: OutreachAttemptResult;
  note: string;
}

const LogOutreachModal = ({
  outreachItem,
  isOpen,
  onToggle
}: ILogOutreachModal) => {
  const { currentUser } = useCurrentUser();
  const toast = useToast();
  const [nextClientAppt, setNextClientAppt] = useState<Appointment>();
  useEffect(() => {
    const fetchNextAppt = async (clientId: string) => {
      const apptService = new AppointmentService();
      const nextAppt = await apptService.getNextClientAppointment(clientId);
      nextAppt && setNextClientAppt(nextAppt);
    };
    if (outreachItem?.purpose === OutreachPurpose.ConfirmAppointment) {
      fetchNextAppt(outreachItem.clientId);
    }
  }, [outreachItem?.purpose, outreachItem?.clientId]);

  const { formState, handleSubmit, register, control } =
    useForm<IOutreachAttemptForm>({
      defaultValues: {
        staffId: currentUser?.uid ?? "",
        staffName: currentUser?.name,
        date: new Date(),
        result: OutreachAttemptResult.Unknown,
        note: ""
      },
      resolver: yupResolver(outreachAttemptResultSchema) as any,
      mode: "onChange"
    });
  const submitForm = async (data: IOutreachAttemptForm) => {
    const { result, note } = data;
    if (!currentUser) {
      toast({
        status: "error",
        description: "You must be logged in to take this action"
      });
      return;
    } else if (!outreachItem) {
      toast({ status: "error", description: "Cannot update outreach item" });
      return;
    }
    const outreachService = new OutreachService();
    const outreachAttemptResultChange = new OutreachAttempt({
      date: new Date(),
      staffId: currentUser.uid,
      staffName: currentUser.name,
      result,
      note
    });
    outreachItem?.attempts.push(outreachAttemptResultChange);
    await outreachService
      .updateOutreach(outreachItem)
      .then(() => {
        toast({
          status: "success",
          description: "Successfully logged Outreach attempt"
        });
        onToggle();
      })
      .catch((err) => {
        console.log("Update Outreach Attempt Error:", err);
        toast({
          status: "error",
          description: "Failed to create outreach attempt"
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <ModalHeader display={"flex"} flexDirection={"column"} pb={0}>
            Log Outreach Attempt
            <Text as={"i"} fontWeight={"normal"}>
              {startCase(outreachItem?.purpose)}
            </Text>
          </ModalHeader>
          <ModalBody>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
              <Text>Patient</Text>
              <Text fontWeight={"bold"}>
                {outreachItem?.clientName?.display}
              </Text>
            </HStack>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
              <Text>Preferred contact method</Text>
              <Text fontWeight={"bold"}>{outreachItem?.prefContactMethod}</Text>
            </HStack>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
              <Text>Phone</Text>
              <Text fontWeight={"bold"}>{outreachItem?.clientPhone}</Text>
            </HStack>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
              <Text>Email</Text>
              <Text fontWeight={"bold"}>{outreachItem?.clientEmail}</Text>
            </HStack>

            {outreachItem?.purpose === OutreachPurpose.ConfirmAppointment &&
              nextClientAppt && (
                <VStack
                  spacing={0}
                  mt={4}
                  p={2}
                  rounded={"lg"}
                  border={"1px solid"}
                  w={"full"}
                  alignItems={"flex-start"}
                >
                  <Text fontWeight={"bold"}>Next Appointment</Text>
                  <HStack w={"full"} justifyContent={"space-between"}>
                    <Text>Date</Text>
                    <Text fontWeight={"bold"}>
                      {DateTime.fromJSDate(nextClientAppt.date!).toFormat(
                        "cccc - ff"
                      )}
                    </Text>
                  </HStack>
                  <HStack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"full"}
                  >
                    <Text>Barbershop</Text>
                    <Text fontWeight={"bold"}>
                      {nextClientAppt.barbershopName}
                    </Text>
                  </HStack>
                  <HStack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"full"}
                  >
                    <Text>Barber</Text>
                    <Text fontWeight={"bold"}>
                      {nextClientAppt.barberName.display}
                    </Text>
                  </HStack>
                  <HStack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"full"}
                  >
                    <Text>Pharmacist</Text>
                    <Text fontWeight={"bold"}>
                      {nextClientAppt.pharmacistName.display}
                    </Text>
                  </HStack>
                </VStack>
              )}
            <VStack mt={4}>
              <Controller
                control={control}
                name='result'
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel>Outreach Attempt Result</FormLabel>
                    <Select placeholder='Make a selection' {...field}>
                      <option value={OutreachAttemptResult.SpokeToClient}>
                        {startCase(OutreachAttemptResult.SpokeToClient)}
                      </option>
                      <option value={OutreachAttemptResult.NoAnswer}>
                        {startCase(OutreachAttemptResult.NoAnswer)}
                      </option>
                      <option value={OutreachAttemptResult.LeftMessage}>
                        {startCase(OutreachAttemptResult.LeftMessage)}
                      </option>
                      <option value={OutreachAttemptResult.WrongContactInfo}>
                        {startCase(OutreachAttemptResult.WrongContactInfo)}
                      </option>
                    </Select>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              ></Controller>
              <FormControl>
                <FormLabel>Note</FormLabel>
                <Textarea {...register("note")} placeholder='Note'></Textarea>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button onClick={onToggle}>Cancel</Button>
              <Button
                colorScheme='blue'
                type='submit'
                isDisabled={!formState.isValid}
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default LogOutreachModal;
