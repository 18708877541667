import { UserType } from "@oben-core-web/constants/core-enums";
import { ClientNoteType } from "@oben-core-web/models/client-note";
import { object, string, date } from "yup";

const clientNoteSchema = object({
  modelId: string(),
  clientId: string().required(),
  authorId: string().required(),
  authorType: string()
    .oneOf(
      [
        UserType.Barber,
        UserType.Pharmacist,
        UserType.Physician,
        UserType.ProgramManager
      ],
      "Invalid user type"
    )
    .required(),
  createdDate: date().required(),
  noteSourceId: string().required("Note must be related to a valid source"),
  noteType: string()
    .oneOf(
      [
        ClientNoteType.Appointment,
        ClientNoteType.CarePlan,
        ClientNoteType.ClientTask,
        ClientNoteType.General,
        ClientNoteType.MedAdherence,
        ClientNoteType.Medication
      ],
      "Invalid note type"
    )
    .required("Note type is required"),
  summary: string().required("Note header is required"),
  details: string().required("Note description is required")
});

export default clientNoteSchema;
