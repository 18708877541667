export class Color {
  constructor(public value: number) {}
}

export class Colors {
  static white = new Color(0xFFFFFFFF);
  static black = new Color(0xFF000000);
  static red = new Color(0xFFFF0000);
  static yellow = new Color(0xFFFFFF00);
  static green = new Color(0xFF00FF00);
  static blue = new Color(0xFF0000FF);
}