import Joyride, { Callback } from "react-joyride";
import { useTourContext as useTour } from "@hooks/useTourContext";

const AppTour = () => {
  const {
    state: { isTourOpen, currentStep, steps },
    // startTour,
    // setCurrentStep,
    next,
    prev,
    endTour
  } = useTour();

  const handleJoyrideCallback: Callback = (callbackData) => {
    const { action, index, lifecycle, status } = callbackData;
    // console.log({ action, lifecycle });
    const currentStep = steps[index];
    if (action === "close") {
      endTour();
    }
    if (status === "finished" || status === "skipped") {
      endTour(); // End the tour when finished or skipped
    } else if (lifecycle === "complete") {
      // Manually advance to the next step if `next` or `prev` action is complete
      if (action === "next" && index < steps.length - 1) {
        if (currentStep.data?.onNext) {
          currentStep.data.onNext.action?.();
          currentStep.data.onNext.navigate?.();
        }
        next();
      } else if (action === "prev" && index > 0) {
        // currentStep.data?.onNavPrev && currentStep.data.onNavPrev();
        if (currentStep.data.onPrev) {
          currentStep.data.onPrev.action?.();
          currentStep.data.onPrev.navigate?.();
        }
        prev();
      } else if (index === 0 || index === steps.length - 1) {
        endTour();
      }
    }
  };

  return (
    <Joyride
      steps={steps}
      run={isTourOpen}
      stepIndex={currentStep}
      callback={handleJoyrideCallback}
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton
      disableOverlayClose={true}
    />
  );
};

export default AppTour;
