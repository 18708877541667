import { DocumentSnapshot } from 'firebase/firestore';

export enum SystemStatus {
  Error = 'Error',
  Up = 'Up',
  MaintenanceScheduled = 'MaintenanceScheduled',
  DownForMaintenance = 'DownForMaintenance',
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class SystemStatusHelper {
  static valueOf(searchString: string | null): SystemStatus | null {
    if (searchString === null) {
      return null;
    }
    return (Object.values(SystemStatus).includes(searchString as SystemStatus)) //
      ? searchString as SystemStatus
      : null;
  }
}

export interface ISystemStatusInfoData {
  status: SystemStatus;
  statusChangeDate: Date;
  adminEditorId: string;
  statusMessage: string;
  maintenanceStartDate: Date | null;
}

// SystemStatusInfo is preserved both in a document (for current) and in a subcollection (for history)
export class SystemStatusInfo {
  status: SystemStatus;
  statusChangeDate: Date;
  adminEditorId: string;
  statusMessage: string;
  maintenanceStartDate: Date | null;

  constructor({ 
    status, 
    statusChangeDate, 
    adminEditorId, 
    statusMessage,
    maintenanceStartDate,
  }: ISystemStatusInfoData) {
    this.status = status;
    this.statusChangeDate = statusChangeDate;
    this.adminEditorId = adminEditorId;
    this.statusMessage = statusMessage;
    this.maintenanceStartDate = maintenanceStartDate;
  }

  static fromFirestore(docSnap: DocumentSnapshot): SystemStatusInfo {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return SystemStatusInfo.fromMap(data);
  }

  static fromMap(data: { [key: string]: any }): SystemStatusInfo {
    return new SystemStatusInfo({
      status: SystemStatusHelper.valueOf(data["status"] ?? "") ?? SystemStatus.Error,
      statusChangeDate: data["statusChangeDate"] ? new Date(data["statusChangeDate"]) : new Date(1, 0, 1), // default to 1/1/0001
      adminEditorId: data["adminEditorId"] ?? "",
      statusMessage: data["statusMessage"] ?? "",
      maintenanceStartDate: data["maintenanceStartDate"] ? new Date(data["maintenanceStartDate"]) : null,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "status": this.status,
      "statusChangeDate": this.statusChangeDate.toISOString(),
      "adminEditorId": this.adminEditorId,
      "statusMessage": this.statusMessage,
      "maintenanceStartDate": this.maintenanceStartDate?.toISOString() ?? null,
    };
  }
}