import React, { useCallback, useEffect, useState } from "react";
import useClientNotes from "@/hooks/useClientNotes";
import {
  Card,
  Flex,
  HStack,
  IconButton,
  VStack,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  Input,
  Button,
  Text,
  ModalOverlay,
  ModalHeader,
  Textarea,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  ModalCloseButton
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { EditIcon } from "@chakra-ui/icons";
import {
  ClientNote,
  ClientNoteType,
  IClientNoteData
} from "@oben-core-web/models/client-note";
import { useForm } from "react-hook-form";
import { ClientNoteService } from "@oben-core-web/services/client-note-service";
import { UserType } from "@oben-core-web/constants/core-enums";
import { Medication } from "@oben-core-web/models/medication";
import { MedAdherence } from "@oben-core-web/models/med-adherence";
import { MedicationService } from "@oben-core-web/services/medication-service";
import { MedAdherenceService } from "@oben-core-web/services/med-adherence-service";
import { MdRefresh } from "react-icons/md";
import { ClientTask } from "@oben-core-web/models/client-task";
import { Appointment } from "@oben-core-web/models/appointment";
import { ClientTaskService } from "@oben-core-web/services/client-task-service";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { WebUser } from "@oben-core-web/models/web-user";
import { WebUserService } from "@oben-core-web/services/web-user-service";
import { yupResolver } from "@hookform/resolvers/yup";
import clientNoteSchema from "@/resolvers/clientNote";

interface IClientNotes {
  clientId: string;
}

const NoteTypeLabels = {
  Unknown: "Miscellaneous",
  Appointment: "Appointment",
  CarePlan: "Care Plan",
  ClientTask: "Client Task",
  MedAdherence: "Medication Adherence",
  Medication: "Medication",
  General: "General"
};

interface ISourceData {
  medication: Medication | null;
  adherence: MedAdherence | null;
  clientTask: ClientTask | null;
  appointment: Appointment | null;
  isCarePlan: boolean;
  isClientNote: boolean;
}

const ClientNotes = ({ clientId }: IClientNotes) => {
  const { clientNotes, refetch } = useClientNotes(clientId);
  return (
    <VStack>
      <MdRefresh onClick={refetch} style={{ alignSelf: "flex-end" }} />
      {clientNotes.map((cn) => (
        <ClientNoteRow
          note={cn}
          key={`client-note-${cn.modelId}`}
          onEditSuccess={refetch}
        />
      ))}
    </VStack>
  );
};

export default ClientNotes;

const ClientNoteRow = ({
  note,
  onEditSuccess
}: {
  note: ClientNote;
  onEditSuccess: () => void;
}) => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [sourceData, setSourceData] = useState<ISourceData>();
  const [author, setAuthor] = useState<WebUser>();
  const { register, handleSubmit, formState } = useForm<IClientNoteData>({
    defaultValues: {
      modelId: note.modelId,
      clientId: note.clientId,
      authorId: note.authorId,
      authorType: note.authorType as UserType,
      createdDate: note.createdDate,
      noteSourceId: note.noteSourceId,
      noteType: note.noteType,
      summary: note.summary,
      details: note.details
    },
    resolver: yupResolver(clientNoteSchema) as any
  });

  const generateSourceData = useCallback(
    ({
      medication = null,
      adherence = null,
      clientTask = null,
      appointment = null,
      isCarePlan = false,
      isClientNote = false
    }: Partial<ISourceData>) => {
      setSourceData({
        medication,
        adherence,
        clientTask,
        appointment,
        isCarePlan,
        isClientNote
      });
    },
    []
  );

  const fetchSource = useCallback(async () => {
    const [noteType, noteSourceId, authorId] = [
      note.noteType,
      note.noteSourceId,
      note.authorId
    ];
    if (!noteType || !noteSourceId || !authorId) {
      console.log("Failed to fetch note source: Missing parameters");
      return;
    }
    const webUserService = new WebUserService();
    const webUser = await webUserService.getWebUser(authorId);
    setAuthor(webUser);
    switch (noteType) {
      case ClientNoteType.Medication: {
        const medService = new MedicationService();
        const medication = await medService.getMedication(noteSourceId);
        generateSourceData({ medication });
        break;
      }
      case ClientNoteType.MedAdherence: {
        const medAdherenceService = new MedAdherenceService();
        const adherence = await medAdherenceService.getMedAdherence(
          noteSourceId
        );
        const medService = new MedicationService();
        const medication = await medService.getMedication(
          adherence.medicationId
        );
        generateSourceData({ medication, adherence });
        break;
      }
      case ClientNoteType.ClientTask: {
        const clientTaskService = new ClientTaskService();
        const clientTask = await clientTaskService.getTask(noteSourceId);
        generateSourceData({ clientTask });
        break;
      }
      case ClientNoteType.Appointment: {
        const appointmentService = new AppointmentService();
        const appointment = await appointmentService.getAppointment(
          noteSourceId
        );
        generateSourceData({ appointment });
        break;
      }
      case ClientNoteType.CarePlan: {
        generateSourceData({ isCarePlan: true });
        break;
      }
      case ClientNoteType.General: {
        generateSourceData({ isClientNote: true });
        break;
      }
      default:
        generateSourceData({});
        break;
    }
  }, [note.noteType, note.noteSourceId, note.authorId, generateSourceData]);

  useEffect(() => {
    fetchSource();
  }, [fetchSource]);

  const updateNote = async (data: IClientNoteData) => {
    setIsLoading(true);
    const note = new ClientNote({ ...data });
    const clientNoteService = new ClientNoteService();
    await clientNoteService
      .updateClientNote(note)
      .then(() => {
        onEditSuccess();
        toast({ status: "success", description: "Successfully updated note" });
        onToggle();
      })
      .catch((e) => {
        console.log("Failed to update note", e);
        toast({ status: "error", description: "Failed to update note" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const relationLabel = sourceData
    ? sourceData.adherence && sourceData.medication
      ? `${sourceData.medication.name} Adherence`
      : sourceData.medication
      ? `${sourceData.medication.name}`
      : sourceData.clientTask
      ? `Client Task: ${sourceData.clientTask.name}`
      : sourceData.appointment
      ? `Appointment on ${sourceData.appointment.date?.toDateString()}`
      : sourceData.isCarePlan
      ? "Care plan note"
      : sourceData.isClientNote
      ? "General note"
      : ""
    : "";

  return (
    <Card w={"full"} p={2}>
      <Flex
        justifyContent={"space-between"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        w={"full"}
      >
        <HStack
          w={"full"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {relationLabel ? (
            <Text as={"i"} fontWeight={"normal"}>
              {relationLabel}
            </Text>
          ) : (
            <></>
          )}
          {author ? (
            <Text as={"i"} color={"gray.500"}>
              {author.name.display}
            </Text>
          ) : (
            <></>
          )}
        </HStack>
        <HStack
          w={"full"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text as='b'>
            {note.summary ??
              NoteTypeLabels[note.noteType as keyof typeof NoteTypeLabels]}
          </Text>
          <HStack>
            <Text fontWeight={"normal"} as={"i"}>
              {DateTime.fromJSDate(note.createdDate).toFormat("MM/dd/yyyy")}
            </Text>
            <IconButton
              aria-label='note-icon'
              variant={"ghost"}
              icon={<EditIcon />}
              size='sm'
              onClick={onToggle}
            />
          </HStack>
        </HStack>
      </Flex>
      <Text fontWeight={"normal"}>{note.details}</Text>

      <Modal isOpen={isOpen} onClose={onToggle}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(updateNote)}>
            <ModalHeader>
              <Text>Edit Note</Text> <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
              <FormControl isInvalid={!!formState.errors.summary}>
                <FormLabel>Header</FormLabel>
                <Input mb={2} {...register("summary")}></Input>
                <FormErrorMessage>
                  {formState.errors.summary?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!formState.errors.details}>
                <FormLabel>Description</FormLabel>
                <Textarea {...register("details")}></Textarea>
                <FormErrorMessage>
                  {formState.errors.details?.message}
                </FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Button isDisabled={isLoading} onClick={onToggle}>
                Cancel
              </Button>
              <Button
                isDisabled={isLoading}
                isLoading={isLoading}
                type={"submit"}
                colorScheme='blue'
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Card>
  );
};
