import { WebUserService } from "@oben-core-web/services/web-user-service";
import {
  signInWithEmailAndPassword,
  signOut,
  updatePassword
} from "firebase/auth";
import { Authentication } from "../../firebase";
import { useToast } from "@chakra-ui/react";
import { useAuthContext } from "./useAuthContext";
import useCurrentUser from "./useCurrentUser";

const useAuthentication = () => {
  const { beforeLogout } = useAuthContext();
  const toast = useToast();
  const { currentUser } = useCurrentUser();

  const login = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }) => {
    if (!Authentication) {
      toast({
        status: "error",
        description: "Missing authentication provider"
      });
      return;
    }
    return signInWithEmailAndPassword(Authentication, email, password)
      .then(async (userCred) => {
        const wus = new WebUserService();
        const isValidUser = await wus.validateWebUser(userCred.user.uid);
        if (!isValidUser) {
          beforeLogout();
          signOut(Authentication);
          toast({
            description:
              "Unable to log in.  If you believe this is an error please contact the Oben team",
            status: "error"
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        throw err;
      });
  };

  const logout = async () => {
    if (!Authentication) {
      console.log("Logout error: No authentication");
      return;
    }
    beforeLogout();
    return await signOut(Authentication)
      .then(() => {
        console.log("Signed out");
      })
      .catch(() => {
        console.log("Unable to sign out");
      });
  };

  // TODO: call reauthenticateWithCredential in cases where current session token has expired
  const changePassword = async (
    password: string,
    updatePasswordResetFlag: boolean = false
  ) => {
    if (!Authentication) {
      console.log("Change password error: No authentication");
      return;
    } else if (!Authentication.currentUser) {
      console.log("User is not logged in");
      return;
    } else if (!currentUser && updatePasswordResetFlag) {
      console.log("Current User not set");
    }
    try {
      await updatePassword(Authentication.currentUser, password).then(
        async () => {
          if (currentUser && updatePasswordResetFlag) {
            const wus = new WebUserService();
            currentUser.pwdNeedsReset = false;
            await wus.updateWebUser(currentUser);
          }
        }
      );
    } catch (error) {
      console.log("Error changing user password", error);
      toast({
        status: "error",
        description: "Something went wrong.  Please reach out to Oben Support"
      });
    }
  };

  return { login, logout, changePassword };
};

export default useAuthentication;
