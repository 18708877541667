import useAppointments from "@/hooks/useAppointments";
import useWebUsers from "@/hooks/useWebUsers";
import { Card, Heading, HStack, Text } from "@chakra-ui/react";
import { UserType } from "@oben-core-web/constants/core-enums";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import {
  LuCircle,
  LuCheckCircle,
  LuCircleOff,
  LuCircleDashed,
  LuCalendar
} from "react-icons/lu";

const DashboardAppointmentScheduling = () => {
  const { webUsers } = useWebUsers([UserType.Pharmacist, UserType.Physician]);
  const { appointments } = useAppointments({
    pharmacistId: webUsers.map((wu) => wu.uid),
    start: DateTime.now().minus({ days: 30 }).toJSDate(),
    end: DateTime.now().plus({ days: 30 }).toJSDate()
  });
  const apptsByStatus = groupBy(appointments, (apt) => {
    const { statusChanges } = apt;
    return statusChanges[statusChanges.length - 1]?.status;
  });
  return (
    <Card w={"60%"} p={5}>
      <Heading as={"h2"} mb={2} fontSize='lg' display={"flex"}>
        <LuCalendar /> &nbsp;Appointments and Scheduling
      </Heading>

      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircle />
          <Text fontWeight={"normal"}>Needs Confirmation</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.New]?.length ?? 0}</Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCheckCircle />
          <Text fontWeight={"normal"}>Confirmed Appointments</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.Confirmed]?.length ?? 0}</Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircleOff />
          <Text fontWeight={"normal"}>Cancellations</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.Canceled]?.length ?? 0}</Text>
      </HStack>
      <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack>
          <LuCircleDashed />
          <Text fontWeight={"normal"}>No Shows</Text>
        </HStack>

        <Text>{apptsByStatus[AppointmentStatus.NoShow]?.length ?? 0}</Text>
      </HStack>
    </Card>
  );
};

export default DashboardAppointmentScheduling;
