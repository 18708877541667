import { object, string, ref } from "yup";

const changePasswordSchema = object({
  password: string()
    .min(6, "Passwords must be at least 6 characters")
    .max(4096, "Passwords must be no more than 4096 characters")
    // .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    // .matches(/\d/, 'Password must contain at least one number')
    // .matches(/[@$!%*?&#]/, 'Password must contain at least one special character')
    .required("Password is required"),
  confirmPassword: string()
    .oneOf([ref("password")], "Passwords must match")
    .required("Password confirmation is required")
});

export default changePasswordSchema;
