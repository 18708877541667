import { VStack, Text, HStack, Button } from "@chakra-ui/react";
import React from "react";
import NavLink from "../atoms/NavLink";
import { FiCalendar, FiHome, FiTag, FiUsers } from "react-icons/fi";
import useCurrentUser from "@/hooks/useCurrentUser";
import { UserType } from "@oben-core-web/constants/core-enums";
import ObenLogo from "@/assets/ObenLogo";
import useTourSteps from "@/hooks/useTourSteps";
import { useTourContext } from "@/hooks/useTourContext";
import { useNavigate } from "react-router-dom";

const NavSidebar = () => {
  const { currentUser } = useCurrentUser();
  const { getTourSteps } = useTourSteps();
  const { startTour } = useTourContext();
  const navigate = useNavigate();
  return (
    <VStack>
      <HStack>
        <ObenLogo height={30} width={30} />
        <Text
          as={"h1"}
          fontSize={"larger"}
          textAlign={"center"}
          lineHeight={"3rem"}
        >
          Oben Health
        </Text>
      </HStack>
      <VStack
        className='nav-sidebar'
        w={"full"}
        h={"full"}
        pos={"relative"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        // px={4}
        fontSize={"large"}
      >
        <NavLink to='/' icon={FiHome} label='Home' />
        <NavLink
          to='/patients'
          icon={FiUsers}
          label='Patients'
          className='patients-nav'
        />
        <NavLink
          to='/appointments'
          icon={FiCalendar}
          label='Appointments'
          className='appointments-nav'
        />
        {currentUser?.userType === UserType.ProgramManager && (
          <NavLink
            to='/payments'
            icon={FiTag}
            label='Payments'
            className='payments-nav'
          />
        )}
      </VStack>
      <Button
        pos={"absolute"}
        bottom={0}
        variant={"outline"}
        size={"xs"}
        w={"14rem"}
        colorScheme='red'
        onClick={() => {
          navigate("/");
          startTour(
            getTourSteps({
              stepSetName: "full"
            })
          );
        }}
      >
        Take a tour
      </Button>
    </VStack>
  );
};

export default NavSidebar;
