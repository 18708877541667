import { useCallback, useEffect, useState } from "react";
import { Outreach, OutreachPurpose } from "@oben-core-web/models/outreach";
import { OutreachService } from "@oben-core-web/services/outreach-service";

const useOutreach = (
  placeBasedCareProvId: string,
  outreachPurposes?: OutreachPurpose[]
) => {
  const [outreaches, setOutreaches] = useState<Outreach[]>([]);

  const fetchOutreaches = useCallback(
    async (placeBasedCareProvId: string, purposes?: OutreachPurpose[]) => {
      if (!placeBasedCareProvId) return;
      const outreachService = new OutreachService();
      const outreachResult =
        await outreachService.getOutreachesByPlaceBasedCareProvId(
          placeBasedCareProvId,
          purposes
        );
      setOutreaches(outreachResult);
    },
    []
  );

  useEffect(() => {
    if (placeBasedCareProvId)
      fetchOutreaches(placeBasedCareProvId, outreachPurposes);
  }, [placeBasedCareProvId, fetchOutreaches, outreachPurposes]);

  return { outreaches, fetchOutreaches };
};

export default useOutreach;
