import { useAppContext } from "@/hooks/useAppContext";
import { useEffectOnce } from "@/hooks/useEffectOnce";
import {
  useDisclosure,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  CloseButton,
  HStack,
  ScaleFade
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, { useEffect } from "react";

const MaintenanceAlert = () => {
  const {
    state: { maintenanceDate, maintenanceNoticeDismissed },
    dismissMaintenanceNotice
  } = useAppContext();
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: !!maintenanceDate && !maintenanceNoticeDismissed
  });

  useEffect(() => {
    !!maintenanceDate && !maintenanceNoticeDismissed && onOpen();
  }, [maintenanceDate, maintenanceNoticeDismissed, onOpen]);

  useEffectOnce(() => {
    setTimeout(() => {
      onClose();
    }, 8000);
  });

  const handleClose = () => {
    dismissMaintenanceNotice();
    onClose();
  };
  return (
    <ScaleFade
      in={isOpen && !maintenanceNoticeDismissed}
      style={{ zIndex: 100, position: "relative" }}
    >
      <Alert
        status='info'
        position={"absolute"}
        top={0}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <HStack>
          <AlertIcon />
          <Box>
            <AlertTitle>Upcoming Maintenance</AlertTitle>
            <AlertDescription fontWeight={"normal"}>
              Maintenance has been scheduled for{" "}
              {maintenanceDate && (
                <b>
                  {DateTime.fromJSDate(maintenanceDate).toFormat(
                    "MMM d 'at' t"
                  )}
                </b>
              )}
              . During this time you will not be able to access the Oben Web
              Portal.
            </AlertDescription>
          </Box>
        </HStack>
        <CloseButton
          alignSelf='flex-start'
          justifySelf={"flex-end"}
          onClick={handleClose}
        />
      </Alert>
    </ScaleFade>
  );
};

export default MaintenanceAlert;
