import {
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  VStack,
  Card
} from "@chakra-ui/react";
import { Outreach } from "@oben-core-web/models/outreach";
import { startCase } from "lodash";
import { DateTime } from "luxon";
import React from "react";

interface IPatientOutreachModal {
  outreach: Outreach;
  isOpen: boolean;
  onToggle: () => void;
}

const PatientOutreachModal = ({
  outreach,
  isOpen,
  onToggle
}: IPatientOutreachModal) => {
  return (
    <Modal isOpen={isOpen} onClose={onToggle} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={"flex"} flexDirection={"column"} pb={0}>
          Outreach Details
          <Text as={"i"} fontWeight={"normal"}>
            {startCase(outreach.purpose)}
          </Text>
        </ModalHeader>
        <ModalBody>
          <HStack alignItems={"center"} justifyContent={"space-between"}>
            <Text>Patient</Text>
            <Text fontWeight={"bold"}>{outreach.clientName?.display}</Text>
          </HStack>
          <HStack alignItems={"center"} justifyContent={"space-between"}>
            <Text>Preferred contact method</Text>
            <Text fontWeight={"bold"}>{outreach.prefContactMethod}</Text>
          </HStack>
          <HStack alignItems={"center"} justifyContent={"space-between"}>
            <Text>Phone</Text>
            <Text fontWeight={"bold"}>{outreach.clientPhone}</Text>
          </HStack>
          <HStack alignItems={"center"} justifyContent={"space-between"}>
            <Text>Email</Text>
            <Text fontWeight={"bold"}>{outreach.clientEmail}</Text>
          </HStack>
          <VStack alignItems={"flex-start"} spacing={2} mt={4}>
            <Text fontWeight={"bold"}>Attempt History</Text>
            {outreach.attempts.map((a, i) => (
              <Card
                key={`outreach-${outreach.id}-attempt-${i}`}
                alignItems={"flex-start"}
                variant='outline'
                p={2}
                w={"full"}
              >
                <HStack
                  w={"full"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Text as={"i"}>{a.staffName.display}</Text>
                  <Text as={"i"}>
                    {DateTime.fromJSDate(a.date).toFormat("ff")}
                  </Text>
                </HStack>
                <Text>{startCase(a.result)}</Text>
                <Text>{a.note}</Text>
              </Card>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PatientOutreachModal;
