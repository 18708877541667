import useClientUser from "@/hooks/useClientUser";
import useCurrentUser from "@/hooks/useCurrentUser";
import usePatients from "@/hooks/usePatients";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  Card,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
  useToast,
  IconButton,
  FormErrorMessage,
  ButtonProps
} from "@chakra-ui/react";
import {
  ClientNote,
  ClientNoteType,
  IClientNoteData
} from "@oben-core-web/models/client-note";
import { ReactElement, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ClientNotes from "./ClientNotes";
import { ClientTaskService } from "@oben-core-web/services/client-task-service";
import { MedicationService } from "@oben-core-web/services/medication-service";
import { ClientNoteService } from "@oben-core-web/services/client-note-service";
import { SlNotebook } from "react-icons/sl";
import { uniqBy } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import clientNoteSchema from "@/resolvers/clientNote";

interface INoteDrawer {
  clientId?: string;
  placement?: "left" | "right" | "top" | "bottom";
  btnProps?: ButtonProps;
  Trigger?: (props: any) => ReactElement;
}

const NoteDrawer = ({
  clientId,
  placement = "left",
  btnProps = {
    position: "absolute",
    left: 4,
    zIndex: 4
  },
  Trigger
}: INoteDrawer) => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const [selectedClientId, setSelectedClientId] = useState(clientId);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { clientUser } = useClientUser(selectedClientId);
  const { clientUsers } = usePatients(currentUser?.placeBasedCareProvId ?? "");
  const [sourceOptions, setSourceOptions] = useState<ReactElement[]>([]);

  const { formState, register, handleSubmit, control, setValue, reset } =
    useForm<IClientNoteData>({
      defaultValues: {
        modelId: "",
        clientId: selectedClientId,
        authorId: currentUser!.uid,
        authorType: currentUser!.userType,
        createdDate: new Date(),
        noteSourceId: "",
        noteType: ClientNoteType.Unknown,
        summary: "",
        details: ""
      },
      resolver: yupResolver(clientNoteSchema) as any
    });

  const fetchSourceOptions = async (noteType: ClientNoteType) => {
    if (!clientUser || !clientUser.currentCarePlanId) return;

    switch (noteType) {
      case ClientNoteType.ClientTask: {
        const clientTaskService = new ClientTaskService();
        const tasks = await clientTaskService.getClientTasks(
          clientUser.uid,
          clientUser.currentCarePlanId
        );
        if (tasks.length)
          setSourceOptions(
            uniqBy(tasks, "clientTaskBaseId").map((t) => (
              <option value={t.modelId} key={t.modelId}>
                {`${t.name} - Due ${t.dueDate?.toDateString()}`}
              </option>
            ))
          );
        return;
      }
      case ClientNoteType.Medication: {
        const medicationService = new MedicationService();
        const meds = await medicationService.getClientMedications(
          clientUser.uid,
          clientUser.currentCarePlanId
        );
        if (meds.length)
          setSourceOptions(
            uniqBy(
              meds
                .filter((m) => !m.rxCancelDate)
                .sort((a, b) => {
                  // ignore upper and lowercase
                  const nameA = a.name.toUpperCase();
                  const nameB = b.name.toUpperCase();
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  // names must be equal
                  return 0;
                }),
              "medBaseId"
            ).map((m) => (
              <option value={m.modelId} key={m.modelId}>
                {m.name} - {m.newDosage.strength}mg
              </option>
            ))
          );
        return;
      }
      default:
        setSourceOptions([]);
        return;
    }
  };

  const onSubmit = async (data: IClientNoteData) => {
    setIsLoading(true);
    const clientNote = new ClientNote(data);
    const clientNoteService = new ClientNoteService();
    await clientNoteService
      .addClientNote(clientNote)
      .then(() => {
        toast({ status: "success", description: "Note added successfully" });
        reset();
      })
      .catch((e) => {
        console.log("Error adding client note:", e);
        toast({ status: "error", description: "Failed to add client note" });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {!isOpen ? (
        Trigger ? (
          <Trigger onClick={onToggle} {...btnProps} />
        ) : (
          <IconButton
            aria-label='Client Notes'
            icon={<SlNotebook />}
            onClick={onToggle}
            variant={"ghost"}
            {...btnProps}
          />
        )
      ) : (
        <div></div>
      )}
      <Drawer
        isOpen={isOpen}
        onClose={onToggle}
        size={"md"}
        placement={placement}
      >
        <DrawerOverlay />
        <DrawerContent bg={"gray.50"}>
          <DrawerHeader
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Heading as={"h1"}>
              {clientUser ? `${clientUser.name.display} ` : "Client "}Notes
            </Heading>
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody>
            {!clientUser ? (
              <Select
                onChange={(e) => {
                  if (!e) {
                    setSelectedClientId("");
                  } else {
                    setSelectedClientId(e.target.value);
                  }
                }}
              >
                {clientUsers.map((cu) => {
                  return <option value={cu.uid}>{cu.name.display}</option>;
                })}
              </Select>
            ) : (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Card p={4} variant={"outline"}>
                    <VStack spacing={2}>
                      <FormControl isInvalid={!!formState.errors.noteType}>
                        <FormLabel>Type</FormLabel>
                        <Controller
                          control={control}
                          name='noteType'
                          render={({ field }) => (
                            <Select
                              {...field}
                              onChange={(e) => {
                                switch (e.target.value) {
                                  case ClientNoteType.CarePlan:
                                    setValue(
                                      "noteSourceId",
                                      clientUser!.currentCarePlanId!
                                    );
                                    setSourceOptions([]);
                                    break;
                                  case ClientNoteType.General:
                                    setValue("noteSourceId", clientUser!.uid);
                                    setSourceOptions([]);
                                    break;
                                  case ClientNoteType.Medication:
                                    fetchSourceOptions(
                                      ClientNoteType.Medication
                                    );
                                    break;
                                  case ClientNoteType.ClientTask:
                                    fetchSourceOptions(
                                      ClientNoteType.ClientTask
                                    );
                                    break;
                                  default:
                                    setValue("noteSourceId", "");
                                    fetchSourceOptions(ClientNoteType.Unknown);
                                    break;
                                }
                                field.onChange(e);
                              }}
                            >
                              <option value={ClientNoteType.Unknown}>
                                Select an option
                              </option>
                              <option value={ClientNoteType.General}>
                                Client Note
                              </option>
                              {clientUser.currentCarePlanId && (
                                <option value={ClientNoteType.CarePlan}>
                                  Care Plan Note
                                </option>
                              )}
                              {clientUser.currentCarePlanId && (
                                <option value={ClientNoteType.Medication}>
                                  Medication Note
                                </option>
                              )}
                              {clientUser.currentCarePlanId && (
                                <option value={ClientNoteType.ClientTask}>
                                  Client Task Note
                                </option>
                              )}
                            </Select>
                          )}
                        />
                        <FormErrorMessage>
                          {formState.errors.noteType?.message}
                        </FormErrorMessage>
                      </FormControl>
                      {sourceOptions.length > 0 && (
                        <FormControl
                          isInvalid={!!formState.errors.noteSourceId}
                        >
                          <FormLabel>Related to</FormLabel>
                          <Select {...register("noteSourceId")}>
                            {sourceOptions.map((o) => o)}
                          </Select>
                          <FormErrorMessage>
                            {formState.errors.noteSourceId?.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                      <FormControl isInvalid={!!formState.errors.summary}>
                        <FormLabel>Header</FormLabel>
                        <Input {...register("summary")} />
                        <FormErrorMessage>
                          {formState.errors.summary?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!formState.errors.details}>
                        <FormLabel>Description</FormLabel>
                        <Textarea {...register("details")} />
                        <FormErrorMessage>
                          {formState.errors.details?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <Button
                        type='submit'
                        colorScheme='blue'
                        w={"full"}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                      >
                        Save Note
                      </Button>
                    </VStack>
                  </Card>
                </form>
                <Card mt={4}>
                  <Accordion allowToggle variant={"ghost"}>
                    <AccordionItem
                      sx={{
                        borderTop: "none",
                        borderBottom: "none"
                      }}
                    >
                      <AccordionButton>
                        <Box
                          as='h2'
                          flex='1'
                          textAlign='left'
                          fontWeight={"bold"}
                          fontSize={"large"}
                        >
                          Notes
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        <ClientNotes clientId={clientUser.uid} />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Card>
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NoteDrawer;
