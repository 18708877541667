import {
  CollectionReference,
  collection,
  DocumentData,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { Database } from "../../firebase";

import { SystemStatusInfo } from "../models/system-status-info";
import { GaLog } from "../utils/ga-log";

export class SystemStatusHistoryService {
  private _collectionPath: string;
  private _collectionReference: CollectionReference<DocumentData>;

  constructor() {
    this._collectionPath = "system/systemStatusInfo/history";
    this._collectionReference = collection(Database, this._collectionPath);
  }

  async getAllSystemStatusHistory(): Promise<SystemStatusInfo[]> {
    try {
      const qSnapshot = await getDocs(this._collectionReference);
      GaLog.readCollection(this._collectionReference.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) => SystemStatusInfo.fromFirestore(docSnap));
    } catch (error) {
      GaLog.readError(this._collectionReference.path, error);
      throw error;
    }
  }

  async addSystemStatusHistory(systemStatusInfo: SystemStatusInfo): Promise<string> {
    try {
      const docRef = await addDoc(this._collectionReference, systemStatusInfo.toJson());
      GaLog.addDocument(this._collectionReference.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(this._collectionReference.path, error);
      throw error;
    }
  }
}
