import React, { useState } from "react";
import DashboardCHWMetrics from "../molecules/DashboardCHWMetrics";
import DashboardPendingPayments from "../molecules/DashboardPendingPayments";
import { Badge, Box, HStack, VStack } from "@chakra-ui/react";
import DashboardEnrolledPatients from "../molecules/DashboardEnrolledPatients";
import DashboardEnrollmentAndScheduling from "../molecules/DashboardEnrollmentAndScheduling";
import PatientOutreachTable from "../molecules/PatientOutreachTable";
import { OutreachPurpose } from "@oben-core-web/models/outreach";

type TProgramManagerDashboardTabs =
  | "allActivities"
  | "enrollment"
  | "scheduling"
  | "apptConfirmation"
  | "payments"
  | "invoices";

const ProgramManagerDashboard = () => {
  const [activeTab, setActiveTab] =
    useState<TProgramManagerDashboardTabs>("allActivities");

  return (
    <Box w={"full"} flex={"1 1 0px"}>
      <VStack h={"full"} flex={"1 1 0px"} display={"flex"}>
        <HStack
          my={4}
          w={"50%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          alignSelf={"flex-start"}
        >
          <Badge
            cursor={"pointer"}
            onClick={() => {
              setActiveTab("allActivities");
            }}
            variant={activeTab === "allActivities" ? "solid" : "outline"}
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            All Activities
          </Badge>
          <Badge
            cursor={"pointer"}
            onClick={() => {
              setActiveTab("enrollment");
            }}
            variant={activeTab === "enrollment" ? "solid" : "outline"}
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            Enrollment
          </Badge>
          <Badge
            cursor={"pointer"}
            onClick={() => {
              setActiveTab("scheduling");
            }}
            variant={activeTab === "scheduling" ? "solid" : "outline"}
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            Scheduling
          </Badge>
          <Badge
            cursor={"pointer"}
            onClick={() => {
              setActiveTab("apptConfirmation");
            }}
            variant={activeTab === "apptConfirmation" ? "solid" : "outline"}
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            Appointment Confirmation
          </Badge>
          <Badge
            cursor={"pointer"}
            onClick={() => {
              setActiveTab("payments");
            }}
            variant={activeTab === "payments" ? "solid" : "outline"}
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            Payments
          </Badge>
          <Badge
            cursor={"pointer"}
            onClick={() => {
              setActiveTab("invoices");
            }}
            variant={activeTab === "invoices" ? "solid" : "outline"}
            colorScheme='blue'
            borderRadius={12}
            py={1}
            px={3}
          >
            Invoices
          </Badge>
        </HStack>
        {activeTab === "allActivities" && (
          <VStack spacing={4} w={"full"}>
            <HStack
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              w={"full"}
              spacing={4}
            >
              <DashboardEnrolledPatients />
              <DashboardEnrollmentAndScheduling />
            </HStack>
            <HStack
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              w={"full"}
              spacing={4}
            >
              <DashboardCHWMetrics />
              <DashboardPendingPayments />
            </HStack>
          </VStack>
        )}
        <div
          style={{
            display: "flex",
            flex: "1 1 0px",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <PatientOutreachTable
            outreachPurposes={
              activeTab === "allActivities"
                ? []
                : activeTab === "enrollment"
                ? [OutreachPurpose.NewEnrollment]
                : activeTab === "apptConfirmation"
                ? [OutreachPurpose.ConfirmAppointment]
                : []
            }
          />
        </div>
      </VStack>
    </Box>
  );
};

export default ProgramManagerDashboard;
