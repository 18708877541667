import {
  CollectionReference,
  collection,
  DocumentReference,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { Database } from "../../firebase";

import { SystemStatusInfo } from "../models/system-status-info";
import { SystemStatusHistoryService } from "./system-status-history-service";
import { GaLog } from "../utils/ga-log";

export class SystemStatusInfoService {
  private _collectionName: string;
  private _collectionReference: CollectionReference<DocumentData>;
  private _documentName: string;
  private _documentReference: DocumentReference<DocumentData>;

  constructor() {
    this._collectionName = "system";
    this._collectionReference = collection(Database, this._collectionName);
    this._documentName = "systemStatusInfo";
    this._documentReference = doc(
      this._collectionReference,
      this._documentName
    );
  }

  // get a stream of changes to SystemStatusInfo document
  streamSystemStatusInfo(
    handleSnapshot: (docSnap: DocumentSnapshot) => void = (
      docSnap: DocumentSnapshot
    ) => {
      if (docSnap.exists()) {
        return SystemStatusInfo.fromFirestore(docSnap);
      } else {
        return SystemStatusInfo.fromMap({});
      }
    }
  ): {
    unsubscribe: (() => void) | undefined;
  } {
    let unsubscribe: (() => void) | undefined;
    try {
      unsubscribe = onSnapshot(
        this._documentReference,
        (docSnap) => {
          handleSnapshot(docSnap);
          GaLog.readDocument(this._documentReference.path, "", {
            isSubscription: true
          });
        },
        (error) => {
          console.error("Error in onSnapshot:", error);
          // TODO: Do we need to throw an error here?
          GaLog.readDocument(this._documentReference.path, "", {
            isSubscription: true
          });
        }
      );

      return { unsubscribe };
    } catch (error) {
      GaLog.readError(this._documentReference.path, error, {
        isSubscription: true
      });
      throw error;
    }
  }

  async getSystemStatusInfo(): Promise<SystemStatusInfo> {
    try {
      const docSnap = await getDoc(this._documentReference);
      if (!docSnap.exists()) {
        throw new Error(`SystemStatusInfo not found`);
      }
      GaLog.readDocument(this._documentReference.path, "");
      return SystemStatusInfo.fromFirestore(docSnap);
    } catch (error) {
      GaLog.readError(this._documentReference.path, error);
      throw error;
    }
  }

  // TODO: wrap in a transaction
  async updateSystemStatusInfo(
    systemStatusInfo: SystemStatusInfo
  ): Promise<void> {
    try {
      await updateDoc(this._documentReference, systemStatusInfo.toJson());
      GaLog.updateDocument(this._documentReference.path, "");
      await new SystemStatusHistoryService().addSystemStatusHistory(
        systemStatusInfo
      ); // we don't care about the id
      return;
    } catch (error) {
      GaLog.updateError(this._documentReference.path, error);
      throw error;
    }
  }
}
