import useClientUser from "@/hooks/useClientUser";
import useMedicationBase from "@/hooks/useMedicationBase";
import useMedications from "@/hooks/useMedications";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  VStack,
  Text,
  Box,
  HStack,
  Button,
  Input,
  Select,
  FormControl,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  IconButton,
  Flex,
  useDisclosure,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay
} from "@chakra-ui/react";
import { FreqPeriod, UserType } from "@oben-core-web/constants/core-enums";
import { ClientNote, ClientNoteType } from "@oben-core-web/models/client-note";
import { MedDosage } from "@oben-core-web/models/med-dosage";
import { Medication } from "@oben-core-web/models/medication";
import { MedicationBase } from "@oben-core-web/models/medication-base";
import { WebUser } from "@oben-core-web/models/web-user";
import { CarePlanTransactionService } from "@oben-core-web/services/care-plan-transaction-service";
import { MedicationService } from "@oben-core-web/services/medication-service";
import { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

interface ICPWizardStep6 {
  carePlanId: string;
  clientId: string;
  pharmacistId: string;
  currentUser: WebUser;
  onSubmit?: () => void;
  submitText?: string;
  onCancel?: () => void;
  cancelText?: string;
}

interface IMedicationForm {
  id: string;
  placeBasedCareProvId: string;
  name: string;
  strength: number;
  freqCount: number;
  freqPeriod: FreqPeriod;
  duration: number;
  medBaseId: string;
  needsMedBase: boolean;
  note?: { noteType: ClientNoteType; summary: string; details: string };
}

interface ICPWizardStep6Form {
  medications: IMedicationForm[];
  unlistedMedications: IMedicationForm[];
}

// TODO?: show old notes for each med row in note modal

const CPWizardStep6 = ({
  carePlanId,
  clientId,
  pharmacistId,
  currentUser,
  onSubmit,
  submitText,
  cancelText,
  onCancel
}: ICPWizardStep6) => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const [noteIdentifier, setNoteIdentifier] = useState<
    | { section: "medications" | "unlistedMedications"; index: number }
    | undefined
  >();
  const { clientUser } = useClientUser(clientId);
  const { medications, refetch } = useMedications({ clientId, carePlanId });
  const { medicationBases } = useMedicationBase(
    currentUser?.placeBasedCareProvId ?? ""
  );
  const onMedSelected = (index: number, selectedMedication: MedicationBase) => {
    setValue(`medications.${index}`, {
      id: "",
      placeBasedCareProvId: selectedMedication.placeBasedCareProvId,
      medBaseId: selectedMedication.id,
      name: selectedMedication.name,
      strength: selectedMedication.defaultDosage.strength,
      freqCount: selectedMedication.defaultDosage.frequencyCount,
      freqPeriod: selectedMedication.defaultDosage
        .frequencyPeriod as FreqPeriod,
      duration: selectedMedication.defaultDosage.durationCount,
      needsMedBase: false
    });
  };
  const { control, register, handleSubmit, setValue, reset, formState } =
    useForm<ICPWizardStep6Form>({
      defaultValues: {
        medications: medications
          .filter((m) => !m.rxCancelDate)
          .map((m) => ({
            id: m.modelId,
            placeBasedCareProvId: m.placeBasedCareProvId,
            name: m.name,
            strength: m.newDosage.strength,
            freqCount: m.newDosage.frequencyCount,
            freqPeriod: m.newDosage.frequencyPeriod,
            duration: m.newDosage.durationCount
          }))
      }
    });
  const {
    fields: medicationFields,
    append: appendMedication,
    remove: removeCurrentMed
  } = useFieldArray({
    control,
    name: "medications"
  });
  const {
    fields: unlistedMedicationFields,
    append: appendUnlistedMedication,
    remove: removeUnlistedMed
  } = useFieldArray({
    control,
    name: "unlistedMedications"
  });

  useEffect(() => {
    if (medications) {
      reset({
        medications: medications
          .filter((m) => !m.rxCancelDate)
          .map((med) => ({
            id: med.modelId,
            placeBasedCareProvId: med.placeBasedCareProvId,
            name: med.name,
            strength: med.newDosage.strength,
            freqCount: med.newDosage.frequencyCount,
            freqPeriod: med.newDosage.frequencyPeriod,
            duration: med.newDosage.durationCount,
            medBaseId: med.medBaseId,
            needsMedBase: false
          }))
      });
    }
  }, [reset, medications]);

  const submissionHandler = async (data: ICPWizardStep6Form) => {
    try {
      const {
        medications: medicationData,
        unlistedMedications: unlistedMedicationData
      } = data;

      const dirtyMedications = formState.dirtyFields.medications;

      const dirtyMedicationRows = medicationData.filter(
        (_, index) => dirtyMedications?.[index]
      );

      const currentMedications =
        dirtyMedicationRows?.map((m) => {
          // map in new dosage to each dirtied row
          const currentMedicationDosage = new MedDosage({
            frequencyCount: m.freqCount,
            frequencyPeriod: m.freqPeriod as FreqPeriod,
            durationCount: m.duration,
            strength: m.strength
          });
          return {
            medication: new Medication({
              modelId: m.id,
              carePlanId,
              clientId,
              name: m.name,
              medBaseId: m.medBaseId,
              placeBasedCareProvId: m.placeBasedCareProvId,
              newDosage: currentMedicationDosage,
              oldDosage: currentMedicationDosage,
              prescriberId: pharmacistId,
              prescriberType: UserType.Pharmacist,
              rxStartDate: new Date(),
              rxCancelDate: null
            }),
            ...(m.note
              ? {
                  note: new ClientNote({
                    modelId: "",
                    clientId,
                    authorId: pharmacistId,
                    authorType: UserType.Pharmacist,
                    createdDate: new Date(),
                    noteType: m.note.noteType,
                    noteSourceId: "",
                    summary: m.note.summary,
                    details: m.note.details
                  })
                }
              : {})
          };
        }) ?? [];
      const unlistedMedications =
        unlistedMedicationData?.map((m) => {
          const currentMedicationDosage = new MedDosage({
            frequencyCount: m.freqCount,
            frequencyPeriod: m.freqPeriod as FreqPeriod,
            durationCount: m.duration,
            strength: m.strength
          });
          return {
            medication: new Medication({
              modelId: "",
              carePlanId,
              clientId,
              name: m.name,
              medBaseId: "",
              placeBasedCareProvId: m.placeBasedCareProvId,
              newDosage: currentMedicationDosage,
              oldDosage: currentMedicationDosage,
              prescriberId: pharmacistId,
              prescriberType: UserType.Pharmacist,
              rxStartDate: new Date(),
              rxCancelDate: null
            }),
            ...(m.note
              ? {
                  note: new ClientNote({
                    modelId: "",
                    clientId,
                    authorId: pharmacistId,
                    authorType: UserType.Pharmacist,
                    createdDate: new Date(),
                    noteType: m.note.noteType,
                    noteSourceId: "",
                    summary: m.note.summary,
                    details: m.note.details
                  })
                }
              : {})
          };
        }) ?? [];
      await CarePlanTransactionService.saveMedUpdates({
        clientId,
        carePlanId,
        currentMedications,
        unlistedMedications
      }).then(() => {
        onSubmit && onSubmit();
      });
    } catch (e) {
      console.log({ error: e });
    }
  };
  const openNoteModal = (
    section: "medications" | "unlistedMedications",
    index: number
  ) => {
    setNoteIdentifier({ section, index });
    onToggle();
  };
  const closeNoteModal = () => {
    setNoteIdentifier(undefined);
    onToggle();
  };
  const removeCurrentMedication = async (index: number) => {
    const medRow = medications.filter((m) => !m.rxCancelDate)[index];
    if (medRow) {
      const medService = new MedicationService();
      medRow.rxCancelDate = new Date();
      await medService.updateMedication(medRow).then(() => {
        refetch();
        removeCurrentMed(index);
        toast({ status: "success", description: "Medication removed" });
      });
    } else {
      console.log("remove unsaved row");
      removeCurrentMed(index);
    }
  };
  return (
    <>
      {clientUser && (
        <form onSubmit={handleSubmit(submissionHandler)}>
          <VStack
            spacing={4}
            key='step6-1'
            alignItems={"flex-start"}
            w={"full"}
            minH={"30rem"}
          >
            <Box w={"full"}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text as={"h2"} fontSize={"large"}>
                  Current Medications
                </Text>
                <HStack>
                  <Button
                    variant='ghost'
                    fontSize={"small"}
                    onClick={() => {
                      appendMedication({
                        id: "",
                        placeBasedCareProvId:
                          currentUser.placeBasedCareProvId ?? "",
                        name: "",
                        medBaseId: "",
                        strength: 0,
                        freqCount: 0,
                        freqPeriod: "Daily" as FreqPeriod,
                        duration: 0,
                        needsMedBase: false
                      });
                    }}
                  >
                    Add Medication
                  </Button>
                  <Button
                    variant='ghost'
                    fontSize={"small"}
                    onClick={() => {
                      appendUnlistedMedication({
                        id: "",
                        placeBasedCareProvId:
                          currentUser.placeBasedCareProvId ?? "",
                        name: "",
                        medBaseId: "",
                        strength: 0,
                        freqCount: 0,
                        freqPeriod: "Daily" as FreqPeriod,
                        duration: 0,
                        needsMedBase: true
                      });
                    }}
                  >
                    Add Unlisted Medication
                  </Button>
                </HStack>
              </Flex>
              <Text fontWeight={"normal"} my={".5rem"}>
                Edit patient medications
              </Text>
              {medicationFields.length > 0 && (
                <Table size='xs'>
                  <Thead>
                    <Tr px={0} fontSize={"small"}>
                      <Td w={"35%"}>Medication Name</Td>
                      <Td w={"15%"}>Dosage (mg)</Td>
                      <Td w={"15%"}>How many times?</Td>
                      <Td w={"15%"}>Over what period?</Td>
                      <Td w={"15%"}>For how long? (days)</Td>
                      <Td w={"5%"}>Actions</Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {medicationFields.map((medField, index) => (
                      <Tr
                        key={`medication-${index}`}
                        // display={'flex'}
                        // alignItems={'center'}
                        // justifyContent={'space-between'}
                      >
                        <Td w={"35%"}>
                          <FormControl>
                            <Controller
                              name={`medications.${index}.medBaseId`}
                              control={control as any}
                              render={({ field }) => (
                                <Select
                                  disabled={medField.medBaseId.length > 0}
                                  placeholder='Select a medication'
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    if (e.target.value) {
                                      const selectedMedication =
                                        medicationBases.find(
                                          (m) => m.id === e.target.value
                                        );
                                      onMedSelected(index, selectedMedication!);
                                    }
                                  }}
                                >
                                  {medicationBases.map((mb) => (
                                    <option key={mb.id} value={mb.id}>
                                      {mb.name}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Input
                              type='number'
                              step='any'
                              {...register(`medications.${index}.strength`)}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(`medications.${index}.freqCount`)}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Select
                              {...register(`medications.${index}.freqPeriod`)}
                            >
                              <option value={"Daily"}>Daily</option>
                              <option value={"Weekly"}>Weekly</option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(`medications.${index}.duration`)}
                            />
                          </FormControl>
                        </Td>
                        <Td>
                          <HStack>
                            {medField.id ? (
                              <DeleteCurrentMedButton
                                confirmDelete={removeCurrentMedication}
                                rowIndex={index}
                              />
                            ) : (
                              <IconButton
                                aria-label='delete-icon'
                                icon={<DeleteIcon />}
                                size='sm'
                                onClick={() => removeCurrentMed(index)}
                              />
                            )}
                            <IconButton
                              aria-label='note-icon'
                              icon={<EditIcon />}
                              size='sm'
                              onClick={() =>
                                openNoteModal("medications", index)
                              }
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}

              {unlistedMedicationFields.length > 0 && (
                <Table size={"xs"}>
                  <Tbody>
                    {unlistedMedicationFields.map((field, index) => (
                      <Tr key={`unlistedMedication-${index}`}>
                        <Td w={"35%"}>
                          <FormControl>
                            <Input
                              {...register(`unlistedMedications.${index}.name`)}
                              placeholder='Enter a medication name'
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.strength`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.freqCount`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Select
                              {...register(
                                `unlistedMedications.${index}.freqPeriod`
                              )}
                            >
                              <option value={"Daily"}>Daily</option>
                              <option value={"Weekly"}>Weekly</option>
                            </Select>
                          </FormControl>
                        </Td>
                        <Td w={"15%"}>
                          <FormControl>
                            <Input
                              type='number'
                              {...register(
                                `unlistedMedications.${index}.duration`
                              )}
                            />
                          </FormControl>
                        </Td>
                        <Td>
                          <HStack>
                            <IconButton
                              aria-label='delete-icon'
                              icon={<DeleteIcon />}
                              size='sm'
                              onClick={() => removeUnlistedMed(index)}
                            />
                            <IconButton
                              aria-label='note-icon'
                              icon={<EditIcon />}
                              size='sm'
                              onClick={() =>
                                openNoteModal("unlistedMedications", index)
                              }
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Box>
          </VStack>
          {noteIdentifier && (
            <Modal isOpen={isOpen} onClose={closeNoteModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Create a Note</ModalHeader>
                <ModalBody>
                  <FormControl>
                    <FormLabel>Header</FormLabel>
                    <Input
                      {...register(
                        `${noteIdentifier.section}.${noteIdentifier.index}.note.summary`
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Details</FormLabel>
                    <Textarea
                      {...register(
                        `${noteIdentifier.section}.${noteIdentifier.index}.note.details`
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Note Type</FormLabel>
                    <Select
                      {...register(
                        `${noteIdentifier.section}.${noteIdentifier.index}.note.noteType`
                      )}
                    >
                      <option value={ClientNoteType.Medication}>
                        Medication
                      </option>
                      <option value={ClientNoteType.MedAdherence}>
                        Adherence
                      </option>
                    </Select>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setValue(
                        `${noteIdentifier.section}.${noteIdentifier.index}.note`,
                        undefined
                      );
                      closeNoteModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={closeNoteModal}>Save</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
          <HStack mt={"2rem"} justifyContent={"space-between"}>
            {onCancel && (
              <Button onClick={onCancel}>{cancelText ?? "Previous"}</Button>
            )}
            {onSubmit && <Button type='submit'>{submitText ?? "Next"}</Button>}
          </HStack>
        </form>
      )}
    </>
  );
};

export default CPWizardStep6;

const DeleteCurrentMedButton = ({
  confirmDelete,
  rowIndex
}: {
  confirmDelete: (deleteIndex: number) => Promise<void>;
  rowIndex: number;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <>
      <IconButton
        aria-label='delete-icon'
        icon={<DeleteIcon />}
        size='sm'
        onClick={onOpen}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Medication
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this medication? This action
              cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme='red'
                onClick={() => {
                  confirmDelete(rowIndex).then(onClose);
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
