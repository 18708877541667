import { Authentication } from "../../firebase";
import { useEffectOnce } from "./useEffectOnce";
import { WebUserService } from "@oben-core-web/services/web-user-service";
import { WebUser } from "@oben-core-web/models/web-user";
import { useCallback, useRef } from "react";
import { User } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";

const useAuthListener = () => {
  const {
    onLogin,
    setDetachAuthListener,
    setFirstTimeLogin,
    state: { detachAuthListener }
  } = useAuthContext();
  const hasRun = useRef<boolean>(false);

  const handleAuthStateChange = useCallback(
    async (user: User | null) => {
      if (user !== null) {
        const webUserService = new WebUserService();
        const isValidUser = await webUserService.validateWebUser(user.uid);
        if (isValidUser) {
          if (!user.metadata.lastSignInTime) {
            setFirstTimeLogin(true);
          }
          const { unsubscribe } = webUserService.streamWebUser(
            user.uid,
            (snapshot) => {
              onLogin(WebUser.fromFirestore(snapshot));
            }
          );
          unsubscribe && setDetachAuthListener(unsubscribe);
        }
      } else {
        detachAuthListener && detachAuthListener();
      }
    },
    [onLogin, detachAuthListener, setDetachAuthListener, setFirstTimeLogin]
  );

  // watch for authState changes
  useEffectOnce(() => {
    if (!hasRun.current) {
      hasRun.current = true;
      Authentication.onAuthStateChanged(handleAuthStateChange);
    }
  });
};

export default useAuthListener;
