import { useState, useEffect, useCallback } from "react";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import { ClientUser } from "@oben-core-web/models/client-user";

const usePatients = (placeBasedCareProvId?: string) => {
  const [clientUsers, setClientUsers] = useState<ClientUser[]>([]);
  const fetchClientUsers = useCallback(async (placeBasedCareProvId: string) => {
    const clientUserService = new ClientUserService();
    const clients = await clientUserService.getPlaceBasedCareProvClientUsers(
      placeBasedCareProvId
    );
    if (clients.length) {
      setClientUsers(clients.filter((c) => c.enabled));
    }
  }, []);
  useEffect(() => {
    let loading = true;
    if (loading && placeBasedCareProvId) {
      fetchClientUsers(placeBasedCareProvId).finally(() => {
        loading = false;
      });
    }
    return () => {
      loading = false;
    };
  }, [placeBasedCareProvId, fetchClientUsers]);
  return { clientUsers };
};

export default usePatients;
