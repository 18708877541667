// Define action types
type TTourAction =
  | { type: "SET_STEPS"; payload: { steps: any[] } }
  | { type: "SET_TOUR_OPEN"; payload: { isTourOpen: boolean } }
  | { type: "SET_CURRENT_STEP"; payload: { currentStep: number } }
  | { type: "SET_CURRENT_STEP"; payload: { currentStep: number } }
  | { type: "NEXT_STEP" }
  | { type: "PREV_STEP" }
  | { type: "RESET" };

// Define the Auth state structure
export interface ITourState {
  isTourOpen: boolean;
  currentStep: number;
  steps: any[];
}

// Initial state
export const initialState: ITourState = {
  isTourOpen: false,
  currentStep: 0,
  steps: []
};

// Reducer function
export const tourReducer = (
  state: ITourState,
  action: TTourAction
): ITourState => {
  switch (action.type) {
    case "SET_STEPS":
      return {
        ...state,
        steps: action.payload.steps
      };
    case "NEXT_STEP":
      if (state.currentStep < state.steps.length - 1) {
        return {
          ...state,
          currentStep: state.currentStep + 1
        };
      }
      return initialState;
    case "PREV_STEP":
      if (state.currentStep > 0) {
        return {
          ...state,
          currentStep: state.currentStep - 1
        };
      }
      return initialState;
    case "SET_TOUR_OPEN":
      return {
        ...state,
        isTourOpen: action.payload.isTourOpen
      };
    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload.currentStep
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};
