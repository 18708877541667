import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage
} from "@chakra-ui/react";
import useCurrentUser from "@/hooks/useCurrentUser";
import useAuthentication from "@/hooks/useAuthentication";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import changePasswordSchema from "@/resolvers/changePassword";

interface IPasswordChangeData {
  password: string;
  confirmPassword: string;
}

const ChangePasswordForm = () => {
  const { currentUser } = useCurrentUser();
  const { isOpen, onClose } = useDisclosure({
    isOpen: currentUser?.pwdNeedsReset
  });
  const toast = useToast();
  const [actionCompleted, setActionCompleted] = useState(false);
  const { changePassword } = useAuthentication();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<IPasswordChangeData>({
    defaultValues: {
      password: "",
      confirmPassword: ""
    },
    mode: "onChange",
    resolver: yupResolver(changePasswordSchema)
  });

  const handleModalClose = () => {
    if (!currentUser?.pwdNeedsReset) {
      onClose();
    } else if (!actionCompleted) {
      toast({
        status: "warning",
        description: "You must change your password"
      });
    }
  };

  const submitForm = async (data: IPasswordChangeData) => {
    const { password } = data;
    await changePassword(password, currentUser?.pwdNeedsReset).then(() => {
      setActionCompleted(true);
      toast({ status: "success", description: "Password changed" });
      onClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} useInert>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <ModalHeader>Change password</ModalHeader>
          <ModalBody>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel>New password</FormLabel>
              <Input type={"password"} {...register("password")} />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.confirmPassword} mt={2}>
              <FormLabel>Re-enter new password</FormLabel>
              <Input type={"password"} {...register("confirmPassword")} />
              <FormErrorMessage>
                {errors.confirmPassword && errors.confirmPassword.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            {!currentUser?.pwdNeedsReset && (
              <Button onClick={onClose}>Cancel</Button>
            )}
            <Button type='submit' colorScheme='blue' isDisabled={!isValid}>
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordForm;
