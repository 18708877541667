import { useContext } from "react";
import { TourContext } from "@/context/TourContext";

export const useTourContext = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error("useTourContext must be used within an AppProvider");
  }
  return context;
};
