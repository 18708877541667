import SchedulerComponent from "../molecules/Scheduler";
import SchedulerAppointmentForm from "../molecules/SchedulerAppointmentForm";
import { Box, Select, useDisclosure } from "@chakra-ui/react";
import useWebUsers from "@/hooks/useWebUsers";
import { UserType } from "@oben-core-web/constants/core-enums";
import { useCallback, useMemo, useState } from "react";
import { WebUser } from "@oben-core-web/models/web-user";
import { DateTime } from "luxon";
import { ClientUser } from "@oben-core-web/models/client-user";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import PatientAppointment from "../organisms/PatientAppointment";

// TODO: add a way to see a client's preferred hours?

const Appointments = () => {
  const [selectedWebUser, setSelectedWebUser] = useState<WebUser | null>(null);
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: appointmentStarted, onToggle: toggleApptStarted } =
    useDisclosure();
  const [appointment, setAppointment] = useState<any>();
  const [appointmentClient, setAppointmentClient] = useState<ClientUser>();
  const webUserParams = useMemo(
    () => [UserType.Pharmacist, UserType.Physician],
    []
  );
  const { webUsers } = useWebUsers(webUserParams);

  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const id = e.target.value;
      if (webUsers && webUsers.length) {
        setSelectedWebUser(webUsers.find((wu) => wu.uid === id)!);
      }
    },
    [webUsers]
  );
  const setSelectedAppointment = useCallback(
    (appt: any) => {
      setAppointment({
        ...appt,
        startDateTime: new Date(appt.startDateTime),
        endDateTime: new Date(appt.endDateTime)
      });
      onToggle();
    },
    [onToggle]
  );
  const setSelectedDate = useCallback(
    (timeRange: { start: Date; end: Date }) => {
      const length = DateTime.fromJSDate(timeRange.end).diff(
        DateTime.fromJSDate(timeRange.start),
        "minutes"
      ).minutes;
      setAppointment({
        startDateTime: timeRange.start,
        length,
        pharmacistId: selectedWebUser?.uid
      });
      onToggle();
    },
    [onToggle, selectedWebUser]
  );

  const userOptions = useMemo(
    () =>
      webUsers.map((wu) => (
        <option key={wu.uid} value={wu.uid}>
          {wu.name.display}
        </option>
      )),
    [webUsers]
  );

  const startClientAppointment = async (clientId: string) => {
    // fetch client record
    const clientUserService = new ClientUserService();
    const client = await clientUserService.getClientUser(clientId);
    setAppointmentClient(client);
    // toggle off schedulerAppointmentForm modal
    onToggle();
    // toggle on patientAppointment modal
    toggleApptStarted();
  };

  const exitWizard = () => {
    // reset appointment to previously selected appointment
    setAppointment(appointment);
    onToggle(); // open up schedulerAppointmentForm modal
    setAppointmentClient(undefined); // clear appointmentClient
    toggleApptStarted(); // close PatientAppointment modal
  };

  return (
    <Box py={4} px={4} bg={"white"} h={"full"}>
      <Select
        mt={2}
        placeholder='Select a Pharmacist'
        onChange={handleSelect}
        className='appointments-pharmacist-select'
      >
        {userOptions}
      </Select>
      <SchedulerComponent
        selectedWebUser={selectedWebUser}
        onEventClick={setSelectedAppointment}
        onDateClick={setSelectedDate}
      />
      <SchedulerAppointmentForm
        dialogOpen={isOpen}
        appointment={appointment}
        disableFormActions={false}
        toggleDialog={() => {
          setAppointment(undefined);
          onToggle();
        }}
        startClientAppointment={startClientAppointment}
      />
      {!!appointmentClient && appointmentStarted && !!appointment && (
        <PatientAppointment
          appointmentStarted={appointmentStarted}
          toggleApptStarted={toggleApptStarted}
          clientUser={appointmentClient}
          appointment={appointment}
          exitWizard={exitWizard}
        />
      )}
    </Box>
  );
};

export default Appointments;
