import React, { useState } from "react";
import {
  Box,
  Text,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepSeparator,
  Center,
  Flex,
  HStack,
  Button,
  StepIcon,
  StepDescription,
  VStack,
  useToast,
  Heading,
  CloseButton
} from "@chakra-ui/react";
import { ClientUser } from "@oben-core-web/models/client-user";
import useCurrentUser from "@/hooks/useCurrentUser";
import CPWizardStep1 from "../molecules/CPWizardStep1";
import CPWizardStep2 from "../molecules/CPWizardStep2";
import CPWizardStep3 from "../molecules/CPWizardStep3";
import CPWizardStep4 from "../molecules/CPWizardStep4";
import CPWizardStep5 from "../molecules/CPWizardStep5";
import CPWizardStep6 from "../molecules/CPWizardStep6";
import CPWizardStep7 from "../molecules/CPWizardStep7";
import CPWizardSummary from "../molecules/CPWizardSummary";
import NoteDrawer from "./NoteDrawer";

interface Props {
  patient: ClientUser;
  carePlanId: string;
  exitWizard: () => void;
  skipBillingReminder?: boolean;
}

// TODO: add resolvers to validate each form
const CarePlanWizard: React.FC<Props> = ({
  patient,
  carePlanId,
  exitWizard,
  skipBillingReminder = true
}) => {
  const toast = useToast();
  const [billingQuestionShown, setBillingQuestionShown] =
    useState(skipBillingReminder);
  const [billingCompleted, setBillingCompleted] = useState(skipBillingReminder);
  const { currentUser } = useCurrentUser();
  const [currentScreen, setCurrentScreen] = useState(0);
  const [currentWindowStep, setCurrentWindowStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleNext = () => {
    if (currentWindowStep < windowLengths[currentScreen] - 1) {
      setCurrentWindowStep(currentWindowStep + 1);
    } else {
      setCurrentScreen(currentScreen + 1);
      setLastActiveStep(currentScreen + 1);
      setCurrentWindowStep(0);
    }
  };

  const handlePrev = () => {
    if (currentWindowStep > 0) {
      setCurrentWindowStep(currentWindowStep - 1);
    } else if (currentScreen > 0) {
      const prevScreenIndex = currentScreen - 1;
      const prevWindowSize = windowLengths[prevScreenIndex] - 1;
      setCurrentWindowStep(prevWindowSize);
      setCurrentScreen(currentScreen - 1);
    }
  };

  const onStepClick = (index: number) => {
    if (index <= lastActiveStep) {
      setCurrentWindowStep(0);
      setCurrentScreen(index);
    }
  };

  const steps = [
    {
      label: "Step 1",
      description: "Blood Pressure",
      subSteps: [
        {
          title: "Understanding Your Blood Pressure",
          subtitle: "Blood Pressure Goal and Risk Factors",
          component: (
            <Box key={"step 1-1"}>
              <CPWizardStep1
                carePlanId={carePlanId}
                clientId={patient.uid}
                onSubmit={handleNext}
                onCancel={exitWizard}
              />
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 2",
      description: "Current Meds",
      subSteps: [
        {
          title: "Understanding Your Blood Pressure",
          subtitle: "Current Medications",
          component: (
            <Box key='step2-1' w={"full"} h={"full"}>
              {currentUser ? (
                // Current medications
                <CPWizardStep2
                  currentUser={currentUser}
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        },
        {
          title: "Understanding Your Care",
          subtitle: "Cholesterol Readings",
          component: (
            <Box key='step4-2' w={"full"}>
              {currentUser ? (
                // Medication adjustments
                <CPWizardStep5
                  currentUser={currentUser}
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 3",
      description: "Lab Results",
      subSteps: [
        {
          title: "Understanding Your Care",
          subtitle: "Cholesterol Readings",
          component: (
            <Box key='step3-1' w={"full"}>
              {currentUser ? (
                // Medication adjustments
                <CPWizardStep5
                  currentUser={currentUser}
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 4",
      description: "Smart Goals",
      subSteps: [
        {
          title: "Your Care Plan",
          subtitle: "Smart Goals",
          component: (
            <Flex
              key='step4-1'
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              h={"full"}
              w={"full"}
              minH={"35rem"}
            >
              <Text fontSize='xx-large' as='h1' mt={"15%"} maxW={"50%"}>
                Now we will talk through creating a plan and setting some smart
                goals
              </Text>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
              </HStack>
            </Flex>
          )
        },
        {
          title: "Understanding Your Care",
          subtitle: "Smart Goals",
          component: (
            <Box key='step4-2' w={"full"}>
              {currentUser ? (
                // Smart goals
                <CPWizardStep3
                  currentUser={currentUser}
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 5",
      description: "Care Actions",
      subSteps: [
        {
          title: "Your Care Plan",
          subtitle: "Healthcare Actions",
          component: (
            <Flex
              key='step5-1'
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              h={"full"}
              w={"full"}
              minH={"35rem"}
            >
              <Text fontSize='xx-large' as='h1' mt={"15%"} maxW={"50%"}>
                Now we will talk through creating a plan and setting some
                healthcare actions
              </Text>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
              </HStack>
            </Flex>
          )
        },
        {
          title: "Understanding Your Care",
          subtitle: "Healthcare Actions",
          component: (
            <Box key='step5-2' w={"full"}>
              {currentUser ? (
                // Health Maintenance Goals
                <CPWizardStep4
                  currentUser={currentUser}
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 6",
      description: "Med Changes",
      subSteps: [
        {
          title: "Your Care Plan",
          subtitle: "Medication Changes",
          component: (
            <Flex
              key='step6-1'
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              h={"full"}
              w={"full"}
              minH={"35rem"}
            >
              <Text fontSize='xx-large' as='h1' mt={"15%"} maxW={"50%"}>
                Now we will talk through changes to your medication
              </Text>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
              </HStack>
            </Flex>
          )
        },
        {
          title: "Understanding Your Care",
          subtitle: "Changes To Your Medication",
          component: (
            <Box key='step6-2' w={"full"}>
              {currentUser ? (
                // Medication adjustments
                <CPWizardStep6
                  currentUser={currentUser}
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 7",
      description: "Monitoring",
      subSteps: [
        {
          title: "Your Care Plan",
          subtitle: "Monitoring and Follow-up",
          component: (
            <Flex
              key='step7-1'
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              h={"full"}
              w={"full"}
              minH={"35rem"}
            >
              <Text fontSize='xx-large' as='h1' mt={"15%"} maxW={"50%"}>
                Now we will create a plan for monitoring and follow up
              </Text>
              <HStack w={"full"} justifyContent={"space-between"}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
              </HStack>
            </Flex>
          )
        },
        {
          title: "Follow up and Monitoring",
          subtitle: "Getting To Know Your Care Team",
          component: (
            <Box key='step7-2' w={"full"}>
              {currentUser ? (
                // Monitoring and Follow Up
                <CPWizardStep7
                  currentUser={currentUser}
                  carePlanId={carePlanId}
                  clientId={patient.uid}
                  onSubmit={handleNext}
                  onCancel={handlePrev}
                  pharmacistId={currentUser.uid}
                />
              ) : (
                <>No Current user</>
              )}
            </Box>
          )
        }
      ]
    },
    {
      label: "Step 8",
      description: "Summary",
      subSteps: [
        {
          title: "Summary",
          subtitle: "Care Plan Summary",
          component: (
            <Center key={"step8-1"}>
              <CPWizardSummary
                carePlanId={carePlanId}
                clientId={patient.uid}
                onSubmit={() => {
                  if (!billingCompleted) {
                    toast({
                      status: "success",
                      description: "Please submit billing for this appointment"
                    });
                  }
                  exitWizard();
                }}
                onCancel={handlePrev}
              />
            </Center>
          )
        }
      ]
    }
  ];

  const windowLengths = steps.map((s) => s.subSteps.length);

  if (!billingQuestionShown) {
    return (
      <VStack
        h={"100vh"}
        w={"100%"}
        display='flex'
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <Heading>Have you completed billing for this appointment?</Heading>
        <HStack mt={"8rem"}>
          <Button
            onClick={() => {
              setBillingQuestionShown(true);
              setBillingCompleted(true);
            }}
          >
            Yes, billing was submitted
          </Button>
          <Button
            onClick={() => {
              setBillingQuestionShown(true);
            }}
          >
            No, I haven't submitted billing yet
          </Button>
        </HStack>
      </VStack>
    );
  }

  return (
    <Box
      py={8}
      px={skipBillingReminder ? 8 : 0}
      w='100%'
      h='fit-content'
      overflowY={"auto"}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      bg={"white"}
      borderRadius={"lg"}
    >
      {skipBillingReminder && (
        <HStack
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"full"}
          pos={"absolute"}
          px={6}
        >
          <NoteDrawer
            clientId={patient.uid}
            btnProps={{ position: "relative", zIndex: 200, variant: "ghost" }}
          />
          <CloseButton
            zIndex={200}
            onClick={() => {
              console.log("clicked");
              exitWizard();
            }}
          />
        </HStack>
      )}
      {steps[currentScreen].subSteps[currentWindowStep].title && (
        <Text fontSize='xx-large' h={"3rem"}>
          {steps[currentScreen].subSteps[currentWindowStep].title}
        </Text>
      )}
      {steps[currentScreen].subSteps[currentWindowStep].subtitle && (
        <Text fontSize='large' h={"3rem"}>
          {steps[currentScreen].subSteps[currentWindowStep].subtitle}
        </Text>
      )}
      <Box w='100%'>
        <Stepper
          size={"md"}
          index={currentScreen}
          w='100%'
          colorScheme='teal'
          mb={8}
          mt={4}
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              onClick={() => {
                onStepClick(index);
              }}
            >
              <StepIndicator>
                <StepStatus
                  active={index === currentScreen}
                  complete={<StepIcon />}
                />
              </StepIndicator>
              <VStack alignItems={"flex-start"} justifyContent={"center"}>
                <StepTitle>{step.label}</StepTitle>
                <StepDescription
                  style={{
                    fontSize: skipBillingReminder ? "x-small" : "smaller"
                  }}
                >
                  {step.description}
                </StepDescription>
              </VStack>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        <Box w='100%' p={4}>
          <Box w={"100%"}>
            {steps[currentScreen].subSteps[currentWindowStep].component}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CarePlanWizard;
