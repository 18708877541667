import { useCallback, useEffect, useState } from "react";
import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { ClientTask } from "@oben-core-web/models/client-task";
import { ClientTaskService } from "@oben-core-web/services/client-task-service";

const useClientTasks = ({
  clientId,
  carePlanId
}: {
  clientId: string;
  carePlanId: string;
}) => {
  const [clientTasks, setClientTasks] = useState<ClientTask[]>([]);

  const handleSubscription = useCallback(
    (querySnapshot: QuerySnapshot<DocumentData>) => {
      const tasks: ClientTask[] = [];
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          tasks.push(ClientTask.fromFirestore(docSnap));
        }
      });
      setClientTasks(tasks);
    },
    []
  );
  useEffect(() => {
    let unsubscribeFunc: (() => void) | undefined;
    if (clientId && carePlanId) {
      const clientTaskService = new ClientTaskService();
      const { unsubscribe } = clientTaskService.streamClientTasks({
        clientId: clientId,
        carePlanId: carePlanId,
        handleSnapshot: handleSubscription
      });
      unsubscribeFunc = unsubscribe;
    }

    return () => {
      if (unsubscribeFunc) {
        unsubscribeFunc();
      }
    };
  }, [handleSubscription, clientId, carePlanId]);

  return clientTasks;
};

export default useClientTasks;
