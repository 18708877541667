import useClientCarePlans from "../../hooks/useClientCarePlans";
import useMedAdherence from "../../hooks/useMedAdherence";
import useMedications from "../../hooks/useMedications";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  IMedAdherenceData,
  MedAdherence
} from "../../../oben-core-web/models/med-adherence";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Medication } from "@oben-core-web/models/medication";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useForm } from "react-hook-form";
import { MedAdherenceService } from "@oben-core-web/services/med-adherence-service";

interface IMedicationAdherenceChart {
  patientId: string;
  toggleChart: () => void;
  height?: number | string;
  width?: number | string;
}

const MedAdherenceChart = ({
  patientId,
  toggleChart,
  ...rest
}: IMedicationAdherenceChart) => {
  const [loading, setLoading] = useState(true);
  const { isOpen, onToggle } = useDisclosure();
  const { currentCarePlan } = useClientCarePlans(patientId);

  const { medications } = useMedications({
    clientId: patientId,
    carePlanId: currentCarePlan?.modelId
  });
  const { medAdherences } = useMedAdherence({
    clientId: patientId,
    carePlanId: currentCarePlan?.modelId
  });

  const medAdherenceByMedication = useMemo<
    (IMedAdherenceData & {
      medName: string;
      dosage?: number;
      medication?: Medication;
    })[]
  >(() => {
    if (!medications || !medAdherences) return [];
    const adherences = medAdherences.map((ma) => {
      const relatedMed = medications.find((m) => m.modelId === ma.medicationId);
      return {
        ...ma,
        medication: relatedMed,
        medName: relatedMed?.name ?? "[ERROR]",
        dosage: relatedMed?.newDosage.strength
      };
    });
    return adherences;
  }, [medications, medAdherences]);

  const adherencesByMedName = _.groupBy(
    medAdherenceByMedication,
    (mawm) => mawm.medName
  );

  const adherenceData = _.reduceRight(
    adherencesByMedName,
    (a, c) => {
      let lastDate: Date;
      const groupMedName = c[0].medName;
      const parsedAdherence = _.reduceRight(
        c,
        (ac, cu) => {
          const row = {
            // ...cu,
            dosage: cu.dosage,
            adherence: cu.response,
            x: groupMedName,
            y: [
              Date.parse(cu.questionDate!.toString()),
              Date.parse(
                (
                  lastDate ??
                  cu.medication?.rxCancelDate ??
                  new Date()
                ).toString()
              )
            ],
            fillColor:
              cu.response === "Always"
                ? "#48BB78"
                : cu.response === "Sometimes"
                ? "#D69E2E"
                : "#E53E3E"
          };
          ac.push(row);
          lastDate = cu.questionDate!;
          return ac;
        },
        [] as any[]
      );
      a[groupMedName] = parsedAdherence;
      return a;
    },
    {} as Record<string, any>
  );

  const adherenceSeries = Object.entries(adherenceData).map(([name, data]) => {
    return {
      name,
      data
    };
  });

  const options: ApexOptions = {
    chart: {
      type: "rangeBar",
      toolbar: {
        show: true,
        tools: {
          download: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [
            {
              icon: '<div style="display: flex; width: 10rem; position: absolute; right: 5.75rem; font-size:smaller;">Record Adherence</div>',
              index: 0,
              title: "Record Adherence",
              class: "custom-icon",
              click: function () {
                onToggle();
              }
            },
            {
              icon: '<div style="display: flex; width: 7.5rem; position: absolute; right: 0; font-size:smaller;">View Medications</div>',
              index: 0,
              title: "View Medications",
              class: "custom-icon",
              click: function () {
                toggleChart();
              }
            }
          ]
        }
      },
      zoom: { enabled: false }
    },
    legend: { show: false },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "75%",
        rangeBarGroupRows: true,

        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900
            },
            formatter: function (val, opts) {
              if (opts !== undefined) {
                // const group = adherenceSeries[opts.seriesIndex];
                const bar =
                  adherenceSeries[opts.seriesIndex].data[opts.dataPointIndex];
                return bar.dosage ? bar.dosage + "mg" : "[Error]";
              }
              return "";
            }
          }
        }
      }
    },
    stroke: {
      width: 1,
      colors: ["white"]
    },
    title: {
      text: "Medication Adherence"
    },
    xaxis: {
      categories: Object.keys(adherenceData),
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MM 'yy",
          day: "M/dd/yy"
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        if (opts !== undefined) {
          // console.log({ val, opts });
          // const group = adherenceSeries[opts.seriesIndex];
          const bar =
            adherenceSeries[opts.seriesIndex].data[opts.dataPointIndex];
          // console.log({ group, bar });
          return bar.dosage ? bar.dosage + "mg" : "[Error]";
          // return bar.adherence;
          // const med = medications.find(
          //   (m) => m.name === group.name && m.newDosage.quantity === bar.dosage
          // );
          // if (med !== undefined) {
          //   const label = (med.newDosage.quantity ?? 0) + "mg";
          //   return label;
          // }
        }
        return "";
      }
    },
    tooltip: {
      custom: function (opts) {
        if (opts !== undefined) {
          const { seriesIndex, dataPointIndex } = opts;
          const series = adherenceSeries[seriesIndex];
          const bar = series.data[dataPointIndex];
          const startDate = new Date(bar.y[0]).toLocaleDateString();
          const endDate = new Date(bar.y[1]).toLocaleDateString();
          return `<div style="padding: 10px; display: flex; flex-direction:column;"><strong>${bar.x}</strong><span><strong>${bar.adherence}</strong>: ${startDate} - ${endDate}</span></div>`;
        }
        return "";
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  if (loading) {
    return (
      <Center h={"10rem"} w={"full"}>
        <Spinner />
      </Center>
    );
  }
  return (
    <>
      <Chart
        options={options}
        series={adherenceSeries}
        type='rangeBar'
        width='100%'
        height={"50%"}
        {...rest}
      />
      <MedAdherenceForm
        clientId={patientId}
        carePlanId={currentCarePlan.modelId}
        isOpen={isOpen}
        onToggle={onToggle}
      />
    </>
  );
};

export default MedAdherenceChart;

const MedAdherenceForm = ({
  clientId,
  carePlanId,
  isOpen,
  onToggle
}: {
  clientId: string;
  carePlanId: string;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const { medications } = useMedications({ clientId, carePlanId });
  const { handleSubmit, setValue, register, reset } =
    useForm<IMedAdherenceData>({
      defaultValues: {
        modelId: "",
        clientId,
        carePlanId,
        medicationId: "",
        medicationBaseId: "",
        placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? "",
        authorId: currentUser?.uid,
        authorType: currentUser?.userType,
        questionDate: null,
        response: ""
      }
    });

  const submissionHandler = async (data: IMedAdherenceData) => {
    setLoading(true);
    if (
      !currentUser ||
      !data.response ||
      !data.medicationId ||
      !data.medicationBaseId
    )
      return;
    const medAdherenceService = new MedAdherenceService();
    const medAdherence = new MedAdherence({
      ...data,
      questionDate: new Date(),
      authorId: currentUser.uid,
      authorType: currentUser.userType,
      placeBasedCareProvId: currentUser.placeBasedCareProvId ?? ""
    });
    await medAdherenceService
      .addMedAdherence(medAdherence)
      .then(() => {
        toast({
          status: "success",
          description: "Medication Adherence recorded successfully!"
        });
        setLoading(false);
        reset();
        onToggle();
      })
      .catch((e) => {
        console.log("Create Med Adherence Error: ", e);
        setLoading(false);
        toast({
          status: "error",
          description: "Failed to create adherence record"
        });
      });
  };
  const filteredMedications = medications.filter((m) => !m.rxCancelDate);
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader>Add Medication Adherence</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Medication</FormLabel>
              <Select
                placeholder={"Select a medication"}
                {...register("medicationId")}
                onChange={(e) => {
                  if (e.target.value) {
                    const specificMedication = filteredMedications.find(
                      (m) => m.modelId === e.target.value
                    );
                    setValue("medicationBaseId", specificMedication!.medBaseId);
                  }
                }}
              >
                {filteredMedications.map((m) => (
                  <option key={m.modelId} value={m.modelId}>
                    {m.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Adherence</FormLabel>
              <Select {...register(`response`)}>
                <option value={"Never"}>Never</option>
                <option value={"Sometimes"}>Sometimes</option>
                <option value={"Always"}>Always</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                reset();
                onToggle();
              }}
              mr={4}
            >
              Cancel
            </Button>
            <Button type='submit' isDisabled={loading}>
              {loading ? <Spinner /> : "Save"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
