import React, { useMemo, useState } from "react";
import Chart from "react-apexcharts";
import useBpReadings from "@/hooks/useBpReadings";
import useClientCarePlans from "@/hooks/useClientCarePlans";
import {
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { CarePlan } from "@oben-core-web/models/care-plan";
import { CarePlanService } from "@oben-core-web/services/care-plan-service";
import { BpGoal } from "@oben-core-web/models/bp-goal";
import { useForm } from "react-hook-form";
import { BpReading } from "@oben-core-web/models/bp-reading";

interface IBPChart {
  canEditGoal?: boolean;
  patientId?: string;
  height?: number | string;
  width?: number | string;
  projectedGoal?: BpGoal;
}

const BPChart = ({
  canEditGoal = false,
  patientId,
  projectedGoal,
  ...rest
}: IBPChart) => {
  const { isOpen, onToggle } = useDisclosure();
  const { bpReadings } = useBpReadings(patientId);
  const { currentCarePlan } = useClientCarePlans(patientId);

  // put BPReadings and BPGoals into a single list, sort by date
  const sortedData: (BpReading | BpGoal)[] = [
    ...bpReadings,
    ...(currentCarePlan?.bpGoals ? currentCarePlan.bpGoals : []),
    ...(projectedGoal ? [projectedGoal] : [])
  ].sort((a, b) => {
    const aDate = a instanceof BpReading ? a.readingDate : a.setDate;
    const bDate = b instanceof BpReading ? b.readingDate : b.setDate;
    return aDate < bDate ? -1 : 1;
  });

  // fill in gaps where we have a reading that doesn't match a goal date and vice versa
  const combinedSeries = sortedData.reduce(
    (a, c) => {
      if (c instanceof BpReading) {
        const x = Date.parse(c.readingDate.toString());
        a.systolic.push({
          x,
          y: c.systolic
        });
        a.diastolic.push({
          x,
          y: c.diastolic
        });
        const lastGoalSystolicValue =
          a.goalSystolic[a.goalSystolic.length - 1]?.y ?? 0;
        const lastGoalDiastolicValue =
          a.goalDiastolic[a.goalDiastolic.length - 1]?.y ?? 0;
        a.goalSystolic.push({ x, y: Number(lastGoalSystolicValue) });
        a.goalDiastolic.push({ x, y: Number(lastGoalDiastolicValue) });
      } else {
        const x = Date.parse(c.setDate.toString());
        a.goalSystolic.push({ x, y: Number(c.systolic) });
        a.goalDiastolic.push({ x, y: Number(c.diastolic) });
        const lastSystolicValue = a.systolic[a.systolic.length - 1]?.y ?? 0;
        const lastDiastolicValue = a.diastolic[a.diastolic.length - 1]?.y ?? 0;
        a.systolic.push({ x, y: lastSystolicValue });
        a.diastolic.push({ x, y: lastDiastolicValue });
      }
      return a;
    },
    {
      systolic: [] as { x: number; y: number }[],
      diastolic: [] as { x: number; y: number }[],
      goalSystolic: [] as { x: number; y: number }[],
      goalDiastolic: [] as { x: number; y: number }[]
    }
  );

  // assign each series to a plotted line or area on the graph
  const chartSeries = useMemo(() => {
    const bpSeries = [
      {
        name: "Systolic",
        data: combinedSeries.systolic,
        type: "line",
        dataLabels: {
          enabled: true
        }
      },
      {
        name: "Diastolic",
        data: combinedSeries.diastolic,
        type: "line",
        dataLabels: {
          enabled: true
        }
      }
    ];
    if (currentCarePlan) {
      const goalSeries = [
        {
          name: "Goal Systolic",
          data: combinedSeries.goalSystolic,
          type: "area",
          dataLabels: {
            enabled: false
          }
        },
        {
          name: "Goal Diastolic",
          data: combinedSeries.goalDiastolic,
          type: "area",
          dataLabels: {
            enabled: false
          }
        }
      ];
      return bpSeries.concat(goalSeries);
    }
    return bpSeries;
  }, [combinedSeries, currentCarePlan]);
  return (
    <div className={"bp-chart"} style={{ height: rest.height ?? "100%" }}>
      <Chart
        type='line'
        width={"82%"}
        height={"50%"}
        {...rest}
        options={{
          title: {
            text: "Blood Pressure"
          },
          chart: {
            id: "vuechart-example",
            toolbar: {
              show: true,
              tools: {
                download: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                ...(canEditGoal
                  ? {
                      customIcons: [
                        {
                          icon: '<div style="display: flex; width: 5.5rem; position: absolute; right: 0; font-size:smaller; z-index: 1">Edit BP Goal</div>',
                          index: 0,
                          title: "Edit BP Goal",
                          class: "custom-icon",
                          click: function () {
                            onToggle();
                          }
                        }
                      ]
                    }
                  : {})
              }
            },
            zoom: {
              enabled: false
            }
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "MM 'yy",
                day: "M/dd/yy"
              }
            }
            // categories: readingDates
          },
          noData: {
            text: "No blood pressure readings for this patient",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "14px",
              fontFamily: undefined
            }
          },
          stroke: {
            curve: "smooth"
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1] // Show values on markers
          }
        }}
        series={chartSeries}
      />
      {currentCarePlan && (
        <BPGoalFormModal
          carePlan={currentCarePlan}
          isOpen={isOpen}
          onToggle={onToggle}
        />
      )}
    </div>
  );
};

export default BPChart;

const BPGoalFormModal = ({
  carePlan,
  isOpen,
  onToggle
}: {
  carePlan: CarePlan;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { bpGoals } = carePlan;
  const lastBpGoal = bpGoals.sort((a, b) =>
    a.setDate > b.setDate ? -1 : 1
  )[0];
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      systolic: lastBpGoal?.systolic,
      diastolic: lastBpGoal?.diastolic
    }
  });
  const submissionHandler = async (data: {
    systolic: number;
    diastolic: number;
  }) => {
    const { dirtyFields } = formState;
    if (dirtyFields) {
      setLoading(true);
      const carePlanService = new CarePlanService();
      const newBpGoals = carePlan.bpGoals.concat([
        new BpGoal({
          systolic: data.systolic,
          diastolic: data.diastolic,
          setDate: new Date()
        })
      ]);
      carePlan.bpGoals = newBpGoals;
      await carePlanService
        .updateCarePlan(carePlan)
        .then(() => {
          toast({ status: "success", description: "New BP goal set" });
          setLoading(false);
          onToggle();
        })
        .catch((error) => {
          console.log("Failed to create BP goal", error);
          toast({ status: "error", description: "Failed to set new BP goal" });
          setLoading(false);
        });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader
            display='flex'
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>Set New Blood Pressure Goal</Text>
            <CloseButton onClick={onToggle} />
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Systolic</FormLabel>
              <Input type='number' {...register("systolic")} />
            </FormControl>
            <FormControl>
              <FormLabel>Diastolic</FormLabel>
              <Input type='number' {...register("diastolic")} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onToggle}>Cancel</Button>
            <Button type='submit' isDisabled={loading}>
              {loading ? <Spinner /> : "Save"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
